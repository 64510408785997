<template>
    <div class="input-range-date-picker">

        <div class="input-wrapper position-relative">
            <img class="calendar-icon position-absolute" src="@/assets/af-icons/calendar-days-solid.svg" alt="calendar-icon" />
            <input type="text" :value="formattedDateRange" placeholder="حدد التاريخ" readonly class="form-control" @click="showDatePicker = !showDatePicker" style="display: block;" :style="{ 'background-color': inputBgColor }" />
        </div>

        <vc-date-picker 
            v-if="showDatePicker"
            v-model="range" 
            :mode="mode" 
            :masks="masks"  
            is-range 
            :locale="$i18n.locale"
            
            :style="{ 'text-align': $i18n.locale == 'ar' ? 'rtl' : 'ltr' }"
            style="z-index: 999"
            class="mt-1 position-absolute"
        >
        
        </vc-date-picker>
    </div>
</template>


<script>
export default {
    name: "input-range-date-picker",
    emits: ["updateValue"],
    props: {
        inputBgColor: {
            type: String,
            required: false
        }
    },
    data(vm) {
    return {
        mode: "date",
        showDatePicker: false,
      range: {
        start: null,
        end: null,
      },
      modelConfig: {
        type: 'string',
        mask: vm.mask,
        start: {
          timeAdjust: vm.mode == 'date' ? '00:00:00' : undefined,
          dateAdjust: vm.mode == 'time' ? vm.dateToString(new Date()) : undefined
        },
        end: {
          timeAdjust: vm.mode == 'date' ? '23:59:59' : undefined,
          dateAdjust: vm.mode == 'time' ? vm.dateToString(new Date()) : undefined
        },

      },
      masks: {
        input: 'YYYY-MM-DD',
      },
    };
  },

  computed: {
    formattedDateRange() {
      if (this.range.start && this.range.end) {
        return `${this.formatDate(this.range.start)} - ${this.formatDate(this.range.end)}`;
      } else {
        return '';
      }
    }
  },

  methods: {
    formatDate(date) {
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const year = d.getFullYear();
      return `${year}-${month}-${day}`;
    }
  },

  watch: {
    range: {
      handler(val) {
        if (val.start != null && val.end != null) {
            this.showDatePicker = false;
            this.$emit('updateValue', {start: this.formatDate(val.start), end: this.formatDate(val.end)});
        }
        // this.$emit('input', val);
      },
      deep: true
    }
  }
}
</script>



<style lang="scss" scoped>
.input-range-date-picker {
    .input-wrapper {
        .calendar-icon {
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
</style>