
<template>
    <div class="radio-input">
        <label 
            v-for="option in options"
            :key="option.value"
            :class="{'radio-option': true, 'active': selectedValue === option.value}"
            role="button"
        >
            <input
                type="radio"
                :name="name"
                :value="option.value"
                v-model="selectedValue"
                @change="updateValue"
                role="radio"
            />
            <span></span>
            {{ option.label }}
        </label>
    </div>
</template>

<script>
export default {
    name: 'BoxedRadioInput',
    props: {
        options: {
            type: Array,
            required: true,
            default: () => []
        },
        value: {
            type: [String, Number],
            default: ''
        },
        name: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            selectedValue: this.value
        };
    },
    methods: {
        updateValue() {
            this.$emit('input', this.selectedValue);
        }
    },
    watch: {
        value(newValue) {
            this.selectedValue = newValue;
        }
    }
};
</script>

<style scoped>

    .radio-input {
        --accent-color: #1FB9B3;
        display: flex;
        flex-direction: row;
        gap:20px;
        font-size: 12px;
        height: 36px;
    }
    
    .radio-option{
        color:#0C2F33;
        border:1px solid #C2C2C2;
        padding:8px 15px;
        border-radius: 8px;
        display: flex;
        gap: 12px;
        align-items: center;
    }
    .radio-option.active {
        border-color: var(--accent-color);
    }
    
    .radio-option input[type="radio"] {
        display: none;
    }
    .radio-option span {
        display: inline-block;
        border: 1px solid #C2C2C2;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        cursor: pointer;
    }
    .radio-option input[type="radio"]:checked + span {
        background-color: var(--accent-color);
        outline: 1px solid var(--accent-color);
        outline-offset: 2px;
        color: white;
    }
</style>