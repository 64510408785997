<template>
  <svg :width="size" :height="size" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.9993 30.3333H11.9993C4.75935 30.3333 1.66602 27.24 1.66602 20V12C1.66602 4.75999 4.75935 1.66666 11.9993 1.66666H14.666C15.2127 1.66666 15.666 2.11999 15.666 2.66666C15.666 3.21332 15.2127 3.66666 14.666 3.66666H11.9993C5.85268 3.66666 3.66602 5.85332 3.66602 12V20C3.66602 26.1467 5.85268 28.3333 11.9993 28.3333H19.9993C26.146 28.3333 28.3327 26.1467 28.3327 20V17.3333C28.3327 16.7867 28.786 16.3333 29.3327 16.3333C29.8793 16.3333 30.3327 16.7867 30.3327 17.3333V20C30.3327 27.24 27.2393 30.3333 19.9993 30.3333Z" :fill="color"/>
                                <path d="M11.3338 23.5867C10.5204 23.5867 9.77377 23.2933 9.2271 22.76C8.57377 22.1067 8.29376 21.16 8.44043 20.16L9.01377 16.1467C9.12043 15.3733 9.6271 14.3733 10.1738 13.8267L20.6804 3.31999C23.3338 0.666653 26.0271 0.666653 28.6804 3.31999C30.1338 4.77332 30.7871 6.25332 30.6538 7.73332C30.5338 8.93332 29.8938 10.1067 28.6804 11.3067L18.1738 21.8133C17.6271 22.36 16.6271 22.8667 15.8538 22.9733L11.8404 23.5467C11.6671 23.5867 11.4938 23.5867 11.3338 23.5867ZM22.0938 4.73332L11.5871 15.24C11.3338 15.4933 11.0404 16.08 10.9871 16.4267L10.4138 20.44C10.3604 20.8267 10.4404 21.1467 10.6404 21.3467C10.8404 21.5467 11.1604 21.6267 11.5471 21.5733L15.5604 21C15.9071 20.9467 16.5071 20.6533 16.7471 20.4L27.2538 9.89332C28.1204 9.02665 28.5738 8.25332 28.6404 7.53332C28.7204 6.66665 28.2671 5.74665 27.2538 4.71999C25.1204 2.58665 23.6538 3.18665 22.0938 4.73332Z" :fill="color"/>
                                <path d="M26.4661 13.1067C26.3728 13.1067 26.2794 13.0933 26.1994 13.0667C22.6928 12.08 19.9061 9.29335 18.9194 5.78668C18.7728 5.25335 19.0794 4.70668 19.6128 4.54668C20.1461 4.40002 20.6928 4.70668 20.8394 5.24002C21.6394 8.08002 23.8928 10.3333 26.7328 11.1333C27.2661 11.28 27.5728 11.84 27.4261 12.3733C27.3061 12.8267 26.9061 13.1067 26.4661 13.1067Z" :fill="color"/>
                                </svg>
</template>

<script>
export default {
    name:'rect-edit-icon',
    props:{
      size:{
        type:[String,Number],
        default:32
      },
      color:{
        type:String,
        default:'white'
      }
    }
}
</script>

<style>

</style>