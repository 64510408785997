<template>
    <TemplateDrawer v-slot="{ clickLink }">

        <div class="box  mx-2 mt-3 ">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M19.9999 30.3333H11.9999C10.2399 30.3333 8.7732 30.16 7.5332 29.7867C7.07987 29.6533 6.78654 29.2133 6.8132 28.7466C7.14654 24.76 11.1865 21.6266 15.9999 21.6266C20.8132 21.6266 24.8399 24.7466 25.1865 28.7466C25.2265 29.2266 24.9332 29.6533 24.4665 29.7867C23.2265 30.16 21.7599 30.3333 19.9999 30.3333ZM8.95987 28.08C9.83987 28.2533 10.8399 28.3333 11.9999 28.3333H19.9999C21.1599 28.3333 22.1599 28.2533 23.0399 28.08C22.3332 25.52 19.4132 23.6266 15.9999 23.6266C12.5865 23.6266 9.66654 25.52 8.95987 28.08Z"
                    fill="#1FB9B3" />
                <path
                    d="M20.0003 2.66663H12.0003C5.33366 2.66663 2.66699 5.33329 2.66699 12V20C2.66699 25.04 4.18699 27.8 7.81366 28.8266C8.10699 25.36 11.667 22.6266 16.0003 22.6266C20.3337 22.6266 23.8937 25.36 24.187 28.8266C27.8137 27.8 29.3337 25.04 29.3337 20V12C29.3337 5.33329 26.667 2.66663 20.0003 2.66663ZM16.0003 18.8933C13.3603 18.8933 11.227 16.7466 11.227 14.1066C11.227 11.4666 13.3603 9.33329 16.0003 9.33329C18.6403 9.33329 20.7737 11.4666 20.7737 14.1066C20.7737 16.7466 18.6403 18.8933 16.0003 18.8933Z"
                    stroke="#1FB9B3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                    d="M15.9999 19.8934C12.8132 19.8934 10.2266 17.2934 10.2266 14.1067C10.2266 10.9201 12.8132 8.33337 15.9999 8.33337C19.1866 8.33337 21.7732 10.9201 21.7732 14.1067C21.7732 17.2934 19.1866 19.8934 15.9999 19.8934ZM15.9999 10.3334C13.9199 10.3334 12.2266 12.0267 12.2266 14.1067C12.2266 16.2001 13.9199 17.8934 15.9999 17.8934C18.0799 17.8934 19.7732 16.2001 19.7732 14.1067C19.7732 12.0267 18.0799 10.3334 15.9999 10.3334Z"
                    fill="#1FB9B3" />
            </svg>

            <router-link custom v-slot="{ navigate }" :to="getRouteLocale('my-profile')" class="mx-2 m-c ">
                <button @click="clickLink(navigate, $event)" role="link" class="btn-drawer"> {{ $t('personal_page')
                }}</button>
            </router-link>
        </div>
        <div class="box  mx-2 mt-3 ">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M19.6932 26.3333H12.3465C11.7598 26.3333 11.0132 26.2666 10.5198 25.76C10.0398 25.2666 10.0532 24.6533 10.0665 24.24V23.0533C6.7065 20.8133 4.49316 16.92 4.49316 13.2133C4.49316 9.70665 6.0665 6.42665 8.79983 4.22665C11.5332 2.02665 15.1198 1.19998 18.6265 1.97331C21.9598 2.70665 24.8398 4.93331 26.3332 7.93331C29.2265 13.76 26.4398 20.0666 21.9865 23.0666V24.0666C21.9998 24.4533 22.0132 25.16 21.4665 25.72C21.0532 26.12 20.4665 26.3333 19.6932 26.3333ZM12.0532 24.32C12.1198 24.32 12.2132 24.3333 12.3332 24.3333H19.6932C19.8265 24.3333 19.9198 24.32 19.9732 24.3066C19.9732 24.2666 19.9732 24.2133 19.9732 24.1733V22.4933C19.9732 22.1466 20.1598 21.8133 20.4532 21.64C24.3998 19.2533 27.0132 13.8 24.5198 8.79998C23.2932 6.33331 20.9198 4.50665 18.1732 3.90665C15.2665 3.26665 12.2932 3.94665 10.0265 5.77331C7.75983 7.59998 6.4665 10.3066 6.4665 13.2133C6.4665 16.92 9.0265 20.12 11.5598 21.6533C11.8665 21.84 12.0398 22.16 12.0398 22.5066V24.3066C12.0532 24.3066 12.0532 24.3066 12.0532 24.32Z"
                    fill="#1FB9B3" />
                <path
                    d="M20.6664 30.3334C20.573 30.3334 20.4797 30.32 20.3864 30.2934C17.5064 29.4667 14.4797 29.4667 11.5997 30.2934C11.0664 30.44 10.5197 30.1334 10.3597 29.6C10.1997 29.0667 10.5197 28.52 11.053 28.36C14.2797 27.44 17.7064 27.44 20.933 28.36C21.4664 28.5067 21.773 29.0667 21.6264 29.6C21.5064 30.0534 21.1064 30.3334 20.6664 30.3334Z"
                    fill="#1FB9B3" />
            </svg>
            <router-link custom v-slot="{ navigate }" :to="getRouteLocale('service-provider-request-purchase-services')"
                class="mx-2 m-c ">
                <button @click="clickLink(navigate, $event)" role="link" class="btn-drawer">{{ $t('requisitions-services')
                }}
                </button>
            </router-link>
        </div>
        <div class="box  mx-2 mt-3 ">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17.3997 25.8934C16.6797 25.8934 15.9197 25.8267 15.093 25.6934L8.83966 24.7067C6.86632 24.4 5.34632 23.8534 4.21299 23.0534C1.29299 21.0267 1.46632 17.5467 1.83966 15.1067L2.82632 8.85336C3.73299 3.12003 6.69299 0.973361 12.4263 1.86669L18.6797 2.85336C21.6263 3.32003 25.5863 4.50669 25.8397 9.48003C25.893 10.3334 25.8397 11.3067 25.653 12.4534L24.6797 18.7067C23.9063 23.6 21.6263 25.8934 17.3997 25.8934ZM10.0397 3.66669C6.86632 3.66669 5.42632 5.28003 4.79966 9.17336L3.81299 15.4267C3.19966 19.36 4.31966 20.6934 5.35966 21.4267C6.23966 22.0534 7.47966 22.48 9.14632 22.7334L15.3997 23.72C20.053 24.4534 21.973 23.0534 22.7063 18.3867L23.6797 12.1334C23.8397 11.1334 23.893 10.2934 23.8397 9.58669V9.57336C23.693 6.77336 22.1063 5.40003 18.3597 4.81336L12.1197 3.84003C11.3463 3.72003 10.6663 3.66669 10.0397 3.66669Z"
                    fill="#1FB9B3" />
                <path
                    d="M19.5737 30.3333C18.4403 30.3333 17.1603 30.0933 15.6803 29.6L9.667 27.6C6.24034 26.4666 4.387 24.84 3.827 22.4666C3.73367 22.0666 3.89367 21.64 4.24034 21.4133C4.587 21.1866 5.04034 21.1866 5.37367 21.4266C6.25367 22.0533 7.48034 22.48 9.147 22.7333L15.4003 23.72C20.0537 24.4533 21.9737 23.0533 22.707 18.3866L23.6803 12.1333C23.8403 11.1333 23.8937 10.2933 23.8403 9.58664C23.827 9.23997 24.0003 8.89331 24.307 8.69331C24.6137 8.49331 25.0003 8.47997 25.3203 8.65331C28.8937 10.56 29.8403 13.6133 28.2937 18.28L26.2937 24.2933C25.347 27.12 24.107 28.8533 22.3737 29.72C21.5337 30.1333 20.6137 30.3333 19.5737 30.3333ZM7.64034 24.48C8.28034 24.9066 9.13367 25.32 10.2937 25.7066L16.307 27.7066C18.6003 28.4666 20.2403 28.5333 21.467 27.9333C22.6937 27.32 23.627 25.96 24.387 23.6666L26.387 17.6533C27.4803 14.3466 27.0537 12.5866 25.787 11.4C25.7603 11.7333 25.707 12.08 25.6537 12.4533L24.6803 18.7066C23.7737 24.44 20.8137 26.5866 15.0803 25.7066L8.827 24.72C8.41367 24.64 8.01367 24.56 7.64034 24.48Z"
                    fill="#1FB9B3" />
                <path
                    d="M10.987 12.9599C9.16033 12.9599 7.66699 11.4666 7.66699 9.63995C7.66699 7.81328 9.16033 6.31995 10.987 6.31995C12.8137 6.31995 14.307 7.81328 14.307 9.63995C14.307 11.4666 12.8137 12.9599 10.987 12.9599ZM10.987 8.33328C10.267 8.33328 9.66699 8.91995 9.66699 9.65328C9.66699 10.3866 10.2537 10.9733 10.987 10.9733C11.707 10.9733 12.307 10.3866 12.307 9.65328C12.307 8.91995 11.707 8.33328 10.987 8.33328Z"
                    fill="#1FB9B3" />
            </svg>

            <a href="#" class="mx-2 m-c ">
                {{ $t('your-offers') }}
            </a>
        </div>

        <!-- uncomment after the jobs modifications -->
        <!-- <div class="box  mx-2 mt-3 ">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M21.3333 30.3333H10.6666C4.50664 30.3333 3.35998 27.4666 3.06664 24.68L2.06664 14C1.91998 12.6 1.87998 10.5333 3.26664 8.98664C4.46664 7.65331 6.45331 7.01331 9.33331 7.01331H22.6666C25.56 7.01331 27.5466 7.66664 28.7333 8.98664C30.12 10.5333 30.08 12.6 29.9333 14.0133L28.9333 24.6666C28.64 27.4666 27.4933 30.3333 21.3333 30.3333ZM9.33331 8.99997C7.07998 8.99997 5.53331 9.43997 4.74664 10.32C4.09331 11.04 3.87998 12.1466 4.05331 13.8L5.05331 24.48C5.27998 26.5866 5.85331 28.3333 10.6666 28.3333H21.3333C26.1333 28.3333 26.72 26.5866 26.9466 24.4666L27.9466 13.8133C28.12 12.1466 27.9066 11.04 27.2533 10.32C26.4666 9.43997 24.92 8.99997 22.6666 8.99997H9.33331Z"
                    fill="#1FB9B3" />
                <path
                    d="M21.3337 8.99996C20.787 8.99996 20.3337 8.54663 20.3337 7.99996V6.93329C20.3337 4.55996 20.3337 3.66663 17.067 3.66663H14.9337C11.667 3.66663 11.667 4.55996 11.667 6.93329V7.99996C11.667 8.54663 11.2137 8.99996 10.667 8.99996C10.1203 8.99996 9.66699 8.54663 9.66699 7.99996V6.93329C9.66699 4.58663 9.66699 1.66663 14.9337 1.66663H17.067C22.3337 1.66663 22.3337 4.58663 22.3337 6.93329V7.99996C22.3337 8.54663 21.8803 8.99996 21.3337 8.99996Z"
                    fill="#1FB9B3" />
                <path
                    d="M15.9997 22.3333C12.333 22.3333 12.333 20.0667 12.333 18.7067V17.3333C12.333 15.4533 12.7863 15 14.6663 15H17.333C19.213 15 19.6663 15.4533 19.6663 17.3333V18.6667C19.6663 20.0533 19.6663 22.3333 15.9997 22.3333ZM14.333 17C14.333 17.1067 14.333 17.2267 14.333 17.3333V18.7067C14.333 20.08 14.333 20.3333 15.9997 20.3333C17.6663 20.3333 17.6663 20.12 17.6663 18.6933V17.3333C17.6663 17.2267 17.6663 17.1067 17.6663 17C17.5597 17 17.4397 17 17.333 17H14.6663C14.5597 17 14.4397 17 14.333 17Z"
                    fill="#1FB9B3" />
                <path
                    d="M18.667 19.6934C18.1737 19.6934 17.7337 19.32 17.6803 18.8134C17.6137 18.2667 18.0003 17.76 18.547 17.6934C22.067 17.2534 25.4403 15.92 28.2803 13.8534C28.7203 13.52 29.347 13.6267 29.6803 14.08C30.0003 14.52 29.907 15.1467 29.4537 15.48C26.3337 17.7467 22.6537 19.2 18.787 19.6934C18.747 19.6934 18.707 19.6934 18.667 19.6934Z"
                    fill="#1FB9B3" />
                <path
                    d="M13.3333 19.7066C13.2933 19.7066 13.2533 19.7066 13.2133 19.7066C9.55999 19.2933 5.99999 17.96 2.91999 15.8533C2.46665 15.5466 2.34665 14.92 2.65332 14.4666C2.95999 14.0133 3.58665 13.8933 4.03999 14.2C6.85332 16.12 10.0933 17.3333 13.4267 17.72C13.9733 17.7866 14.3733 18.28 14.3067 18.8266C14.2667 19.3333 13.84 19.7066 13.3333 19.7066Z"
                    fill="#1FB9B3" />
            </svg>

            <a href="#" class="mx-2 m-c ">
                {{ $t('jobs') }}
            </a>
        </div> -->

        <div class="box  mx-2 mt-3 ">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.667 7.66663C10.1203 7.66663 9.66699 7.21329 9.66699 6.66663V2.66663C9.66699 2.11996 10.1203 1.66663 10.667 1.66663C11.2137 1.66663 11.667 2.11996 11.667 2.66663V6.66663C11.667 7.21329 11.2137 7.66663 10.667 7.66663Z"
                    fill="#1FB9B3" />
                <path
                    d="M21.333 7.66663C20.7863 7.66663 20.333 7.21329 20.333 6.66663V2.66663C20.333 2.11996 20.7863 1.66663 21.333 1.66663C21.8797 1.66663 22.333 2.11996 22.333 2.66663V6.66663C22.333 7.21329 21.8797 7.66663 21.333 7.66663Z"
                    fill="#1FB9B3" />
                <path
                    d="M11.3333 19.3333C11.16 19.3333 10.9867 19.2934 10.8267 19.2267C10.6533 19.16 10.52 19.0667 10.3867 18.9467C10.1467 18.6933 10 18.36 10 18C10 17.8267 10.04 17.6533 10.1067 17.4933C10.1733 17.3333 10.2667 17.1867 10.3867 17.0534C10.52 16.9334 10.6533 16.84 10.8267 16.7733C11.3067 16.5733 11.9067 16.68 12.28 17.0534C12.52 17.3067 12.6667 17.6533 12.6667 18C12.6667 18.08 12.6533 18.1734 12.64 18.2667C12.6267 18.3467 12.6 18.4267 12.56 18.5067C12.5333 18.5867 12.4933 18.6667 12.44 18.7467C12.4 18.8133 12.3333 18.88 12.28 18.9467C12.0267 19.1867 11.68 19.3333 11.3333 19.3333Z"
                    fill="#1FB9B3" />
                <path
                    d="M16.0003 19.3333C15.827 19.3333 15.6537 19.2933 15.4937 19.2266C15.3203 19.16 15.187 19.0666 15.0537 18.9466C14.8137 18.6933 14.667 18.36 14.667 18C14.667 17.8266 14.707 17.6533 14.7737 17.4933C14.8403 17.3333 14.9337 17.1866 15.0537 17.0533C15.187 16.9333 15.3203 16.8399 15.4937 16.7733C15.9737 16.5599 16.5737 16.68 16.947 17.0533C17.187 17.3066 17.3337 17.6533 17.3337 18C17.3337 18.08 17.3203 18.1733 17.307 18.2666C17.2937 18.3466 17.267 18.4266 17.227 18.5066C17.2003 18.5866 17.1603 18.6666 17.107 18.7466C17.067 18.8133 17.0003 18.8799 16.947 18.9466C16.6937 19.1866 16.347 19.3333 16.0003 19.3333Z"
                    fill="#1FB9B3" />
                <path
                    d="M20.6663 19.3333C20.493 19.3333 20.3197 19.2933 20.1597 19.2266C19.9863 19.16 19.853 19.0666 19.7197 18.9466C19.6663 18.8799 19.613 18.8133 19.5597 18.7466C19.5063 18.6666 19.4663 18.5866 19.4397 18.5066C19.3997 18.4266 19.373 18.3466 19.3597 18.2666C19.3463 18.1733 19.333 18.08 19.333 18C19.333 17.6533 19.4797 17.3066 19.7197 17.0533C19.853 16.9333 19.9863 16.8399 20.1597 16.7733C20.653 16.5599 21.2397 16.68 21.613 17.0533C21.853 17.3066 21.9997 17.6533 21.9997 18C21.9997 18.08 21.9863 18.1733 21.973 18.2666C21.9597 18.3466 21.933 18.4266 21.893 18.5066C21.8663 18.5866 21.8263 18.6666 21.773 18.7466C21.733 18.8133 21.6663 18.8799 21.613 18.9466C21.3597 19.1866 21.013 19.3333 20.6663 19.3333Z"
                    fill="#1FB9B3" />
                <path
                    d="M11.3333 24C11.16 24 10.9867 23.96 10.8267 23.8933C10.6667 23.8267 10.52 23.7333 10.3867 23.6133C10.1467 23.36 10 23.0133 10 22.6666C10 22.4933 10.04 22.32 10.1067 22.16C10.1733 21.9866 10.2667 21.84 10.3867 21.72C10.88 21.2266 11.7867 21.2266 12.28 21.72C12.52 21.9733 12.6667 22.32 12.6667 22.6666C12.6667 23.0133 12.52 23.36 12.28 23.6133C12.0267 23.8533 11.68 24 11.3333 24Z"
                    fill="#1FB9B3" />
                <path
                    d="M16.0003 24C15.6537 24 15.307 23.8533 15.0537 23.6133C14.8137 23.36 14.667 23.0133 14.667 22.6666C14.667 22.4933 14.707 22.32 14.7737 22.16C14.8403 21.9866 14.9337 21.84 15.0537 21.72C15.547 21.2266 16.4537 21.2266 16.947 21.72C17.067 21.84 17.1603 21.9866 17.227 22.16C17.2937 22.32 17.3337 22.4933 17.3337 22.6666C17.3337 23.0133 17.187 23.36 16.947 23.6133C16.6937 23.8533 16.347 24 16.0003 24Z"
                    fill="#1FB9B3" />
                <path
                    d="M20.6663 24C20.3197 24 19.973 23.8533 19.7197 23.6133C19.5997 23.4933 19.5063 23.3467 19.4397 23.1733C19.373 23.0133 19.333 22.84 19.333 22.6667C19.333 22.4933 19.373 22.32 19.4397 22.16C19.5063 21.9867 19.5997 21.84 19.7197 21.72C20.0263 21.4134 20.493 21.2667 20.9197 21.36C21.013 21.3733 21.093 21.4 21.173 21.44C21.253 21.4667 21.333 21.5067 21.413 21.56C21.4797 21.6 21.5463 21.6667 21.613 21.72C21.853 21.9734 21.9997 22.32 21.9997 22.6667C21.9997 23.0133 21.853 23.36 21.613 23.6133C21.3597 23.8533 21.013 24 20.6663 24Z"
                    fill="#1FB9B3" />
                <path
                    d="M27.3337 13.12H4.66699C4.12033 13.12 3.66699 12.6667 3.66699 12.12C3.66699 11.5733 4.12033 11.12 4.66699 11.12H27.3337C27.8803 11.12 28.3337 11.5733 28.3337 12.12C28.3337 12.6667 27.8803 13.12 27.3337 13.12Z"
                    fill="#1FB9B3" />
                <path
                    d="M21.3333 30.3333H10.6667C5.8 30.3333 3 27.5333 3 22.6666V11.3333C3 6.46663 5.8 3.66663 10.6667 3.66663H21.3333C26.2 3.66663 29 6.46663 29 11.3333V22.6666C29 27.5333 26.2 30.3333 21.3333 30.3333ZM10.6667 5.66663C6.85333 5.66663 5 7.51996 5 11.3333V22.6666C5 26.48 6.85333 28.3333 10.6667 28.3333H21.3333C25.1467 28.3333 27 26.48 27 22.6666V11.3333C27 7.51996 25.1467 5.66663 21.3333 5.66663H10.6667Z"
                    fill="#1FB9B3" />
            </svg>


            <a href="#" class="mx-2 m-c ">
                {{ $t('schedules-your-services') }}
            </a>
        </div>
        <div class="box  mx-2 mt-3 ">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M23.653 30.3334H8.34634C5.02634 30.3334 2.33301 27.64 2.33301 24.32V15.3467C2.33301 12.0267 5.02634 9.33337 8.34634 9.33337H23.653C26.973 9.33337 29.6663 12.0267 29.6663 15.3467V17.2667C29.6663 17.8134 29.213 18.2667 28.6663 18.2667H25.973C25.5063 18.2667 25.0797 18.4401 24.773 18.7601L24.7597 18.7734C24.3863 19.1334 24.213 19.6267 24.253 20.1333C24.333 21.0133 25.173 21.72 26.133 21.72H28.6663C29.213 21.72 29.6663 22.1733 29.6663 22.72V24.3067C29.6663 27.64 26.973 30.3334 23.653 30.3334ZM8.34634 11.3334C6.13301 11.3334 4.33301 13.1334 4.33301 15.3467V24.32C4.33301 26.5334 6.13301 28.3334 8.34634 28.3334H23.653C25.8663 28.3334 27.6663 26.5334 27.6663 24.32V23.7334H26.133C24.1197 23.7334 22.413 22.24 22.253 20.32C22.1463 19.2267 22.5463 18.1467 23.3463 17.3601C24.0397 16.6534 24.973 16.2667 25.973 16.2667H27.6663V15.3467C27.6663 13.1334 25.8663 11.3334 23.653 11.3334H8.34634Z"
                    fill="#1FB9B3" />
                <path
                    d="M3.33301 17.5466C2.78634 17.5466 2.33301 17.0933 2.33301 16.5466V10.4534C2.33301 8.46671 3.58634 6.66663 5.43967 5.95997L16.0263 1.95997C17.1197 1.54663 18.333 1.69336 19.2797 2.36003C20.2397 3.0267 20.7997 4.10669 20.7997 5.26669V10.3333C20.7997 10.88 20.3463 11.3333 19.7997 11.3333C19.253 11.3333 18.7997 10.88 18.7997 10.3333V5.26669C18.7997 4.76002 18.5597 4.29334 18.133 4.00001C17.7063 3.70667 17.1997 3.64 16.7197 3.82667L6.13301 7.82666C5.05301 8.24 4.31967 9.29337 4.31967 10.4534V16.5466C4.33301 17.1066 3.87967 17.5466 3.33301 17.5466Z"
                    fill="#1FB9B3" />
                <path
                    d="M26.1333 23.7332C24.12 23.7332 22.4133 22.2399 22.2533 20.3199C22.1466 19.2132 22.5466 18.1333 23.3466 17.3466C24.0266 16.6533 24.96 16.2666 25.96 16.2666H28.7333C30.0533 16.3066 31.0666 17.3465 31.0666 18.6265V21.3733C31.0666 22.6533 30.0533 23.6932 28.7733 23.7332H26.1333ZM28.7066 18.2666H25.9733C25.5066 18.2666 25.08 18.4399 24.7733 18.7599C24.3866 19.1333 24.2 19.6399 24.2533 20.1466C24.3333 21.0266 25.1733 21.7332 26.1333 21.7332H28.7466C28.92 21.7332 29.08 21.5733 29.08 21.3733V18.6265C29.08 18.4265 28.92 18.2799 28.7066 18.2666Z"
                    fill="#1FB9B3" />
                <path
                    d="M18.6663 17H9.33301C8.78634 17 8.33301 16.5467 8.33301 16C8.33301 15.4533 8.78634 15 9.33301 15H18.6663C19.213 15 19.6663 15.4533 19.6663 16C19.6663 16.5467 19.213 17 18.6663 17Z"
                    fill="#1FB9B3" />
            </svg>



            <router-link custom v-slot="{ navigate }" :to="getRouteLocale('network-menu-subscribe')" class="mx-2 m-c ">
                <button @click="clickLink(navigate, $event)" role="link" class="btn-drawer">{{ $t('subscribes') }}</button>
            </router-link>
        </div>
        <div class="box  mx-2 mt-3 ">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M25.893 15.6666H21.333C20.7863 15.6666 20.333 15.2133 20.333 14.6666V5.34662C20.333 4.35996 20.7197 3.43996 21.413 2.74663C22.1063 2.05329 23.0263 1.66663 24.013 1.66663H24.0263C25.693 1.67996 27.2663 2.33329 28.4663 3.51996C29.6663 4.73329 30.3197 6.33329 30.3197 7.99996V11.2266C30.333 13.88 28.5463 15.6666 25.893 15.6666ZM22.333 13.6666H25.893C27.4397 13.6666 28.333 12.7733 28.333 11.2266V7.99996C28.333 6.85329 27.8797 5.75996 27.0663 4.93329C26.253 4.13329 25.1597 3.67996 24.0263 3.66663C24.0263 3.66663 24.0263 3.66663 24.013 3.66663C23.573 3.66663 23.1463 3.83996 22.8263 4.15996C22.5063 4.47996 22.333 4.89329 22.333 5.34662V13.6666Z"
                    fill="#1FB9B3" />
                <path
                    d="M12.0003 31.1066C11.3737 31.1066 10.787 30.8666 10.347 30.4133L8.13366 28.1866C8.01366 28.0666 7.82699 28.0533 7.69366 28.16L5.40033 29.8666C4.69366 30.4 3.76033 30.4933 2.96033 30.0933C2.16033 29.6933 1.66699 28.8933 1.66699 28V7.99996C1.66699 3.97329 3.97366 1.66663 8.00033 1.66663H24.0003C24.547 1.66663 25.0003 2.11996 25.0003 2.66663C25.0003 3.21329 24.547 3.66663 24.0003 3.66663C23.0803 3.66663 22.3337 4.41329 22.3337 5.33329V28C22.3337 28.8933 21.8403 29.6933 21.0403 30.0933C20.2403 30.4933 19.307 30.4133 18.6003 29.88L16.3203 28.1733C16.187 28.0666 16.0003 28.0933 15.8937 28.2L13.6537 30.44C13.2137 30.8666 12.627 31.1066 12.0003 31.1066ZM7.88033 26.0933C8.49366 26.0933 9.09366 26.32 9.54699 26.7866L11.7603 29.0133C11.8403 29.0933 11.947 29.1066 12.0003 29.1066C12.0537 29.1066 12.1603 29.0933 12.2403 29.0133L14.4803 26.7733C15.307 25.9466 16.6137 25.8666 17.5337 26.5733L19.8003 28.2666C19.947 28.3733 20.0803 28.3333 20.147 28.2933C20.2137 28.2533 20.3337 28.1733 20.3337 28V5.33329C20.3337 4.73329 20.4803 4.15996 20.7337 3.66663H8.00033C5.04033 3.66663 3.66699 5.03996 3.66699 7.99996V28C3.66699 28.1866 3.78699 28.2666 3.85366 28.3066C3.93366 28.3466 4.06699 28.3733 4.20033 28.2666L6.48033 26.56C6.89366 26.2533 7.38699 26.0933 7.88033 26.0933Z"
                    fill="#1FB9B3" />
                <path
                    d="M16 18.3467H12C11.4533 18.3467 11 17.8933 11 17.3467C11 16.8 11.4533 16.3467 12 16.3467H16C16.5467 16.3467 17 16.8 17 17.3467C17 17.8933 16.5467 18.3467 16 18.3467Z"
                    fill="#1FB9B3" />
                <path
                    d="M16 13.0133H12C11.4533 13.0133 11 12.56 11 12.0133C11 11.4666 11.4533 11.0133 12 11.0133H16C16.5467 11.0133 17 11.4666 17 12.0133C17 12.56 16.5467 13.0133 16 13.0133Z"
                    fill="#1FB9B3" />
                <path
                    d="M7.96029 13.3467C7.22695 13.3467 6.62695 12.7467 6.62695 12.0134C6.62695 11.2801 7.22695 10.6801 7.96029 10.6801C8.69362 10.6801 9.29362 11.2801 9.29362 12.0134C9.29362 12.7467 8.69362 13.3467 7.96029 13.3467Z"
                    fill="#1FB9B3" />
                <path
                    d="M7.96029 18.68C7.22695 18.68 6.62695 18.08 6.62695 17.3466C6.62695 16.6133 7.22695 16.0133 7.96029 16.0133C8.69362 16.0133 9.29362 16.6133 9.29362 17.3466C9.29362 18.08 8.69362 18.68 7.96029 18.68Z"
                    fill="#1FB9B3" />
            </svg>



            <!-- <router-link custom v-slot="{ navigate }" :to="getRouteLocale('my-financial-transactions')" class="mx-2 m-c ">
                <button @click="clickLink(navigate, $event)" role="link" class="btn-drawer">{{
                    $t('My-financial-transactions') }}</button>
            </router-link> -->
        </div>
        <div class="box  mx-2 mt-3 ">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15.9999 21.7734C12.8132 21.7734 10.2266 19.1867 10.2266 16C10.2266 12.8134 12.8132 10.2267 15.9999 10.2267C19.1866 10.2267 21.7732 12.8134 21.7732 16C21.7732 19.1867 19.1866 21.7734 15.9999 21.7734ZM15.9999 12.2267C13.9199 12.2267 12.2266 13.92 12.2266 16C12.2266 18.08 13.9199 19.7734 15.9999 19.7734C18.0799 19.7734 19.7732 18.08 19.7732 16C19.7732 13.92 18.0799 12.2267 15.9999 12.2267Z"
                    fill="#1FB9B3" />
                <path
                    d="M16.0004 28.0267C10.9871 28.0267 6.25376 25.0934 3.00043 20.0001C1.5871 17.8001 1.5871 14.2134 3.00043 12.0001C6.2671 6.90672 11.0004 3.97339 16.0004 3.97339C21.0004 3.97339 25.7338 6.90672 28.9871 12.0001C30.4004 14.2001 30.4004 17.7867 28.9871 20.0001C25.7338 25.0934 21.0004 28.0267 16.0004 28.0267ZM16.0004 5.97339C11.6938 5.97339 7.57376 8.56006 4.69376 13.0801C3.69376 14.6401 3.69376 17.3601 4.69376 18.9201C7.57376 23.4401 11.6938 26.0267 16.0004 26.0267C20.3071 26.0267 24.4271 23.4401 27.3071 18.9201C28.3071 17.3601 28.3071 14.6401 27.3071 13.0801C24.4271 8.56006 20.3071 5.97339 16.0004 5.97339Z"
                    fill="#1FB9B3" />
            </svg>
            <router-link custom v-slot="{ navigate }" :to="getRouteLocale('preview-profile')" class="mx-2 m-c ">
                <button @click="clickLink(navigate, $event)" role="link" class="btn-drawer">{{ $t('how-see-me-others')
                }}</button>
            </router-link>
        </div>
        <div class="box  mx-2 mt-3 ">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M24 14.3333C23.4533 14.3333 23 13.88 23 13.3333V10.6666C23 6.46663 21.8133 3.66663 16 3.66663C10.1867 3.66663 9 6.46663 9 10.6666V13.3333C9 13.88 8.54667 14.3333 8 14.3333C7.45333 14.3333 7 13.88 7 13.3333V10.6666C7 6.79996 7.93333 1.66663 16 1.66663C24.0667 1.66663 25 6.79996 25 10.6666V13.3333C25 13.88 24.5467 14.3333 24 14.3333Z"
                    fill="#1FB9B3" />
                <path
                    d="M22.667 30.3334H9.33366C3.45366 30.3334 1.66699 28.5467 1.66699 22.6667V20C1.66699 14.12 3.45366 12.3334 9.33366 12.3334H22.667C28.547 12.3334 30.3337 14.12 30.3337 20V22.6667C30.3337 28.5467 28.547 30.3334 22.667 30.3334ZM9.33366 14.3334C4.56033 14.3334 3.66699 15.24 3.66699 20V22.6667C3.66699 27.4267 4.56033 28.3334 9.33366 28.3334H22.667C27.4403 28.3334 28.3337 27.4267 28.3337 22.6667V20C28.3337 15.24 27.4403 14.3334 22.667 14.3334H9.33366Z"
                    fill="#1FB9B3" />
                <path
                    d="M10.6663 22.6667C10.493 22.6667 10.3197 22.6267 10.1597 22.56C9.98633 22.4933 9.85302 22.4 9.71969 22.28C9.47969 22.0266 9.33301 21.6933 9.33301 21.3333C9.33301 21.16 9.37299 20.9867 9.43966 20.8267C9.50632 20.6533 9.59969 20.52 9.71969 20.3867C9.85302 20.2667 9.98633 20.1733 10.1597 20.1066C10.6397 19.8933 11.2397 20.0133 11.613 20.3867C11.733 20.52 11.8264 20.6667 11.893 20.8267C11.9597 20.9867 11.9997 21.16 11.9997 21.3333C11.9997 21.68 11.853 22.0266 11.613 22.28C11.3597 22.52 11.013 22.6667 10.6663 22.6667Z"
                    fill="#1FB9B3" />
                <path
                    d="M16.0003 22.6668C15.6537 22.6668 15.307 22.5201 15.0537 22.2801C14.8137 22.0267 14.667 21.6934 14.667 21.3334C14.667 21.1601 14.6936 20.9867 14.7736 20.8267C14.8403 20.6667 14.9337 20.5201 15.0537 20.3868C15.3603 20.0801 15.827 19.9334 16.2537 20.0267C16.347 20.0401 16.427 20.0667 16.507 20.1067C16.587 20.1334 16.667 20.1734 16.747 20.2268C16.8137 20.2668 16.8803 20.3334 16.947 20.3868C17.067 20.5201 17.1603 20.6667 17.227 20.8267C17.2937 20.9867 17.3337 21.1601 17.3337 21.3334C17.3337 21.6934 17.187 22.0267 16.947 22.2801C16.8803 22.3334 16.8137 22.3867 16.747 22.4401C16.667 22.4934 16.587 22.5334 16.507 22.5601C16.427 22.6001 16.347 22.6268 16.2537 22.6401C16.1737 22.6534 16.0803 22.6668 16.0003 22.6668Z"
                    fill="#1FB9B3" />
                <path
                    d="M21.3333 22.6668C20.9733 22.6668 20.64 22.5201 20.3867 22.2801C20.2667 22.1467 20.1733 22.0001 20.1066 21.8401C20.04 21.6801 20 21.5068 20 21.3334C20 20.9868 20.1467 20.6401 20.3867 20.3868C20.4533 20.3334 20.52 20.2801 20.5867 20.2268C20.6667 20.1734 20.7467 20.1334 20.8267 20.1067C20.9067 20.0667 20.9867 20.0401 21.0667 20.0267C21.5067 19.9334 21.96 20.0801 22.28 20.3868C22.52 20.6401 22.6667 20.9734 22.6667 21.3334C22.6667 21.5068 22.6267 21.6801 22.56 21.8401C22.4934 22.0134 22.4 22.1467 22.28 22.2801C22.0267 22.5201 21.68 22.6668 21.3333 22.6668Z"
                    fill="#1FB9B3" />
            </svg>
            <router-link custom v-slot="{ navigate }" :to="getRouteLocale('change-password')" class="mx-2 m-c ">
                <button @click="clickLink(navigate, $event)" role="link" class="btn-drawer">{{ $t('password-change-request')
                }}</button>
            </router-link>
        </div>

    </TemplateDrawer>
</template>

<script>
import TemplateDrawer from '@/layouts/tamplate/drawer/index.vue'

export default {
    name: 'drawer-profil',
    props: {
        dashboard: {
            type: String,
            default: 'network-dashboard'
        }
    },
    components: {
        TemplateDrawer
    },
    methods: {
        clickLink(navigate, evnt) {
            //this.myModal.hide();
            window.$(`#btn-close-drawer`).click()
            navigate(evnt)
        },
    }
}
</script>

<style scoped>
.btn-drawer {
    background: transparent;
    border: none;
}
</style>