import LayoutDefault from "@/layouts/workspaces/index.vue";

const routers = [
    {
        path: "workspaces/home",
        meta: { layout: LayoutDefault, title: "Workspaces Home" },
        name: "workspaces-home",
        component: () =>
            import(
                /* webpackChunkName: "workspaces-home" */ "../views/workspaces/home/index.vue"
            ),
    },
    {
        path: "workspaces/reservations",
        meta: { layout: LayoutDefault, title: "Workspaces Reservations" },
        name: "workspaces-reservations",
        component: () =>
            import(
                /* webpackChunkName: "workspaces-home" */ "../views/workspaces/reservations/index.vue"
            ),
    },
    {
        path: "workspaces/overview",
        meta: { layout: LayoutDefault, title: "Workspaces Overview" },
        name: "workspaces-overview",
        component: () =>
            import(
                /* webpackChunkName: "workspaces-home" */ "../views/workspaces/overview/index.vue"
            ),
    },
    {
        path: "workspaces/add",
        meta: { layout: LayoutDefault, title: "Add Workspace" },
        name: "add-workspace",
        component: () =>
            import(
                /* webpackChunkName: "workspaces-home" */ "../views/workspaces/add/index.vue"
            ),
    },
    {
        path: "workspaces/requests",
        meta: { layout: LayoutDefault, title: "Workspaces Requests" },
        name: "workspaces-requests",
        component: () =>
            import(
                /* webpackChunkName: "workspaces-home" */ "../views/workspaces/requests/index.vue"
            ),
    },
    {
        path: "workspaces/:id",
        meta: { layout: LayoutDefault, title: "Workspaces Details" },
        name: "workspaces-details",
        component: () =>
            import(
                /* webpackChunkName: "workspaces-home" */ "../views/workspaces/details/index.vue"
            ),
    },
    {
        path: "workspaces/:id(\\d+)/payment",
        meta: { layout: LayoutDefault, title: "Workspaces Details" },
        name: "workspaces-payment",
        component: () =>
            import(
                /* webpackChunkName: "workspaces-home" */ "../views/workspaces/details/WorkspaceBookingPayment.vue"
            ),
    },
    {
        path: "workspaces/:id(\\d+)/payment/invoice",
        meta: { layout: LayoutDefault, title: "Payment Invoice" },
        name: "InvoiceDetails",
        component: () =>
            import(
                /* webpackChunkName: "workspaces-home" */ "../views/workspaces/details/InvoiceDetails.vue"
            ),
    },
    {
        path: "workspaces/:id(\\d+)/pay-first",
        meta: { layout: LayoutDefault, title: "Workspaces Details" },
        name: "workspaces-pay",
        component: () =>
            import(
                /* webpackChunkName: "workspaces-home" */ "../components/pages/workspaces/reservations/check-out/body-check-out.vue"
            ),
    },
    {
        path: "workspaces/:any(.*)*",
        meta: { layout: LayoutDefault },
        name: "NotFound-workspaces",
        component: () =>
            import(
                /* webpackChunkName: "workspaces-home" */ "../views/workspaces/home/index.vue"
            ),
    },
];

export default routers;
