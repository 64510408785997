<template>
  <svg xmlns="http://www.w3.org/2000/svg"
   xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0,0,256,256"
    :width="size"
     :height="size"
      fill-rule="nonzero">
      <g :fill="color"
      
      fill-rule="nonzero"
       stroke="none" stroke-width="1"
      stroke-linecap="butt"
       stroke-linejoin="miter" 
       stroke-miterlimit="10"
        stroke-dasharray=""
         stroke-dashoffset="0"
          font-family="none"
           font-weight="none"
           font-size="none" 
           text-anchor="none" 
           style="mix-blend-mode: normal"
           ><g transform="scale(5.12,5.12)">
            <path d="M25,2c-12.6907,0 -23,10.3093 -23,23c0,12.69071 10.3093,23 23,23c12.69071,0 23,-10.30929 23,-23c0,-12.6907 -10.30929,-23 -23,-23zM25,4c11.60982,0 21,9.39018 21,21c0,11.60982 -9.39018,21 -21,21c-11.60982,0 -21,-9.39018 -21,-21c0,-11.60982 9.39018,-21 21,-21zM24,13v11h-11v2h11v11h2v-11h11v-2h-11v-11z"></path></g></g></svg>
</template>

<script>
export default {
    props:{
        color:{
            type:String,
            default:'#1fb9b3'
        },
        size:{
            type:[String,Number],
            default:'500px'
        }
    }

}
</script>

<style>

</style>