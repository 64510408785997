<template>
    <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 16.75C11.6 16.75 11.2 16.72 10.82 16.65C8.7 16.34 6.77 15.12 5.55 13.31C4.7 12.03 4.25 10.54 4.25 9C4.25 4.73 7.73 1.25 12 1.25C16.27 1.25 19.75 4.73 19.75 9C19.75 10.54 19.3 12.03 18.45 13.31C17.22 15.13 15.29 16.34 13.15 16.66C12.8 16.72 12.4 16.75 12 16.75ZM12 2.75C8.55 2.75 5.75 5.55 5.75 9C5.75 10.25 6.11 11.45 6.79 12.47C7.78 13.93 9.33 14.91 11.05 15.16C11.69 15.27 12.32 15.27 12.91 15.16C14.66 14.91 16.21 13.92 17.2 12.46C17.88 11.44 18.24 10.24 18.24 8.98999C18.25 5.54999 15.45 2.75 12 2.75Z" :fill="color"/>
<path d="M6.47128 22.59C6.33128 22.59 6.20128 22.57 6.06128 22.54C5.41128 22.39 4.91128 21.89 4.76128 21.24L4.41128 19.77C4.39128 19.68 4.32128 19.61 4.22128 19.58L2.57128 19.19C1.95128 19.04 1.46128 18.58 1.29128 17.97C1.12128 17.36 1.29128 16.7 1.74128 16.25L5.64128 12.35C5.80128 12.19 6.02128 12.11 6.24128 12.13C6.46128 12.15 6.66128 12.27 6.79128 12.46C7.78128 13.92 9.33128 14.91 11.0613 15.16C11.7013 15.27 12.3313 15.27 12.9213 15.16C14.6713 14.91 16.2213 13.92 17.2113 12.46C17.3313 12.27 17.5413 12.15 17.7613 12.13C17.9813 12.11 18.2013 12.19 18.3613 12.35L22.2613 16.25C22.7113 16.7 22.8813 17.36 22.7113 17.97C22.5413 18.58 22.0413 19.05 21.4313 19.19L19.7813 19.58C19.6913 19.6 19.6213 19.67 19.5913 19.77L19.2413 21.24C19.0913 21.89 18.5913 22.39 17.9413 22.54C17.2913 22.7 16.6213 22.47 16.2013 21.96L12.0013 17.13L7.80128 21.97C7.46128 22.37 6.98128 22.59 6.47128 22.59ZM6.09128 14.03L2.80128 17.32C2.71128 17.41 2.72128 17.51 2.74128 17.57C2.75128 17.62 2.80128 17.72 2.92128 17.74L4.57128 18.13C5.22128 18.28 5.72128 18.78 5.87128 19.43L6.22128 20.9C6.25128 21.03 6.35128 21.07 6.41128 21.09C6.47128 21.1 6.57128 21.11 6.66128 21.01L10.4913 16.6C8.79128 16.27 7.23128 15.36 6.09128 14.03ZM13.5113 16.59L17.3413 20.99C17.4313 21.1 17.5413 21.1 17.6013 21.08C17.6613 21.07 17.7513 21.02 17.7913 20.89L18.1413 19.42C18.2913 18.77 18.7913 18.27 19.4413 18.12L21.0913 17.73C21.2113 17.7 21.2613 17.61 21.2713 17.56C21.2913 17.51 21.3013 17.4 21.2113 17.31L17.9213 14.02C16.7713 15.35 15.2213 16.26 13.5113 16.59Z" :fill="color"/>
<path d="M13.8911 12.8903C13.6311 12.8903 13.3211 12.8203 12.9511 12.6003L12.0011 12.0302L11.0511 12.5902C10.1811 13.1102 9.61112 12.8102 9.40112 12.6602C9.19112 12.5102 8.74112 12.0603 8.97112 11.0703L9.21112 10.0403L8.41112 9.30023C7.97112 8.86023 7.81112 8.33025 7.96112 7.85025C8.11112 7.37025 8.55112 7.03024 9.17112 6.93024L10.2411 6.75024L10.7511 5.63025C11.0411 5.06025 11.4911 4.74023 12.0011 4.74023C12.5111 4.74023 12.9711 5.07026 13.2511 5.64026L13.8411 6.82025L14.8311 6.94025C15.4411 7.04025 15.8811 7.38023 16.0411 7.86023C16.1911 8.34023 16.0311 8.87024 15.5911 9.31024L14.7611 10.1403L15.0211 11.0703C15.2511 12.0603 14.8011 12.5102 14.5911 12.6602C14.4811 12.7502 14.2411 12.8903 13.8911 12.8903ZM9.61112 8.39026L10.3011 9.08023C10.6211 9.40023 10.7811 9.94025 10.6811 10.3802L10.4911 11.1802L11.2911 10.7102C11.7211 10.4602 12.3011 10.4602 12.7211 10.7102L13.5211 11.1802L13.3411 10.3802C13.2411 9.93025 13.3911 9.40023 13.7111 9.08023L14.4011 8.39026L13.5311 8.24023C13.1111 8.17023 12.6911 7.86026 12.5011 7.48026L12.0011 6.50024L11.5011 7.50024C11.3211 7.87024 10.9011 8.19025 10.4811 8.26025L9.61112 8.39026Z" :fill="color"/>
</svg>
   </template>
   
   <script>
   export default {
       name:'d-medal-star-icon',
     props:{
       size:{
         type:[String,Number],
         default:24
       },
       color:{
         type:String,
         default:'currentColor'
       }
     }
   }
   </script>