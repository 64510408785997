<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.2195 9.75H8.95953C8.39953 9.75 7.83954 9.56001 7.39954 9.20001L5.20953 7.45001C4.60953 6.97001 4.26953 6.26 4.26953 5.5C4.26953 4.74 4.60953 4.01999 5.20953 3.54999L7.39954 1.79999C7.83954 1.44999 8.39953 1.25 8.95953 1.25H17.2195C18.5995 1.25 19.7195 2.37 19.7195 3.75V7.25C19.7195 8.63 18.5995 9.75 17.2195 9.75ZM8.95953 2.75C8.72953 2.75 8.50954 2.83 8.33954 2.97L6.14954 4.72C5.90954 4.91 5.76953 5.2 5.76953 5.5C5.76953 5.8 5.90954 6.09 6.14954 6.28L8.33954 8.03C8.51954 8.17 8.73953 8.25 8.95953 8.25H17.2195C17.7695 8.25 18.2195 7.8 18.2195 7.25V3.75C18.2195 3.2 17.7695 2.75 17.2195 2.75H8.95953Z" :fill="color"/>
<path d="M15.0608 19.75H6.80078C5.42078 19.75 4.30078 18.63 4.30078 17.25V13.75C4.30078 12.37 5.42078 11.25 6.80078 11.25H15.0608C15.6308 11.25 16.1808 11.44 16.6208 11.8L18.8108 13.55C19.4108 14.03 19.7508 14.74 19.7508 15.5C19.7508 16.26 19.4108 16.98 18.8108 17.45L16.6208 19.2C16.1808 19.56 15.6308 19.75 15.0608 19.75ZM6.80078 12.75C6.25078 12.75 5.80078 13.2 5.80078 13.75V17.25C5.80078 17.8 6.25078 18.25 6.80078 18.25H15.0608C15.2908 18.25 15.5108 18.17 15.6808 18.03L17.8708 16.28C18.1108 16.09 18.2508 15.8 18.2508 15.5C18.2508 15.2 18.1108 14.91 17.8708 14.72L15.6808 12.97C15.5008 12.83 15.2808 12.75 15.0608 12.75H6.80078Z" :fill="color"/>
<path d="M12 12.75C11.59 12.75 11.25 12.41 11.25 12V9C11.25 8.59 11.59 8.25 12 8.25C12.41 8.25 12.75 8.59 12.75 9V12C12.75 12.41 12.41 12.75 12 12.75Z" :fill="color"/>
<path d="M12 22.75C11.59 22.75 11.25 22.41 11.25 22V19C11.25 18.59 11.59 18.25 12 18.25C12.41 18.25 12.75 18.59 12.75 19V22C12.75 22.41 12.41 22.75 12 22.75Z" :fill="color"/>
<path d="M15 22.75H9C8.59 22.75 8.25 22.41 8.25 22C8.25 21.59 8.59 21.25 9 21.25H15C15.41 21.25 15.75 21.59 15.75 22C15.75 22.41 15.41 22.75 15 22.75Z" :fill="color"/>
</svg>
</template>

<script>
export default {
name:'sign-post-icon',
props:{
    size:{
      type:[String,Number],
      default:24
    },
    color:{
      type:String,
      default:'#979797'
    }
  }
}
</script>

<style>

</style>