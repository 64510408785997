<template>
  <svg :width="size" :height="size" viewBox="0 0 16 16" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.66659 9.83342H1.33325C1.05992 9.83342 0.833252 9.60675 0.833252 9.33342V5.08008C0.833252 4.86674 0.966579 4.68006 1.15991 4.60673C1.35991 4.5334 1.57992 4.59342 1.71326 4.75342C2.11992 5.24008 2.75326 5.51341 3.37992 5.49341C3.91326 5.48007 4.40657 5.28009 4.77991 4.92676C4.95324 4.78009 5.09324 4.60675 5.19991 4.41341C5.40658 4.06008 5.50659 3.6734 5.49992 3.28007C5.48659 2.66674 5.21993 2.10674 4.7666 1.70007C4.6066 1.56007 4.55325 1.34006 4.62659 1.14673C4.69992 0.953395 4.8866 0.820068 5.09326 0.820068H9.99992C10.2733 0.820068 10.4999 1.04674 10.4999 1.32007V7.98674C10.4999 9.0134 9.67992 9.83342 8.66659 9.83342ZM1.83325 8.83342H8.66659C9.12659 8.83342 9.49992 8.46008 9.49992 8.00008V1.83341H6.12659C6.35992 2.26675 6.48659 2.75342 6.49992 3.26009C6.51325 3.84009 6.36658 4.41341 6.07324 4.91341C5.91324 5.20008 5.69325 5.47342 5.45325 5.67342C4.91991 6.18009 4.18658 6.48008 3.40658 6.50008C2.84658 6.52008 2.30659 6.38008 1.83993 6.12675V8.83342H1.83325Z"
                :fill="color" />
            <path
                d="M12.6666 13.8333H11.9999C11.7266 13.8333 11.4999 13.6066 11.4999 13.3333C11.4999 12.8733 11.1266 12.4999 10.6666 12.4999C10.2066 12.4999 9.83325 12.8733 9.83325 13.3333C9.83325 13.6066 9.60658 13.8333 9.33325 13.8333H6.66658C6.39325 13.8333 6.16658 13.6066 6.16658 13.3333C6.16658 12.8733 5.79325 12.4999 5.33325 12.4999C4.87325 12.4999 4.49992 12.8733 4.49992 13.3333C4.49992 13.6066 4.27325 13.8333 3.99992 13.8333H3.33325C1.95325 13.8333 0.833252 12.7133 0.833252 11.3333V9.33325C0.833252 9.05992 1.05992 8.83325 1.33325 8.83325H8.66658C9.12659 8.83325 9.49992 8.45992 9.49992 7.99992V3.33325C9.49992 3.05992 9.72658 2.83325 9.99992 2.83325H11.2266C11.8866 2.83325 12.4932 3.1866 12.8199 3.75993L13.9599 5.75326C14.0466 5.90659 14.0466 6.09992 13.9599 6.25326C13.8733 6.40659 13.7066 6.49992 13.5266 6.49992H12.6666C12.5733 6.49992 12.4999 6.57325 12.4999 6.66659V8.66659C12.4999 8.75992 12.5733 8.83325 12.6666 8.83325H14.6666C14.9399 8.83325 15.1666 9.05992 15.1666 9.33325V11.3333C15.1666 12.7133 14.0466 13.8333 12.6666 13.8333ZM12.4333 12.8333H12.6666C13.4933 12.8333 14.1666 12.1599 14.1666 11.3333V9.83325H12.6666C12.0266 9.83325 11.4999 9.30659 11.4999 8.66659V6.66659C11.4999 6.02659 12.0199 5.49992 12.6666 5.49992L11.9532 4.25326C11.8066 3.99326 11.5266 3.83325 11.2266 3.83325H10.4999V7.99992C10.4999 9.01325 9.67992 9.83325 8.66658 9.83325H1.83325V11.3333C1.83325 12.1599 2.50659 12.8333 3.33325 12.8333H3.56657C3.78657 12.0666 4.49325 11.4999 5.33325 11.4999C6.17325 11.4999 6.87993 12.0666 7.09993 12.8333H8.90658C9.12658 12.0666 9.83326 11.4999 10.6733 11.4999C11.5133 11.4999 12.2133 12.0666 12.4333 12.8333Z"
                :fill="color" />
            <path
                d="M5.33333 15.1667C4.32 15.1667 3.5 14.3467 3.5 13.3333C3.5 12.32 4.32 11.5 5.33333 11.5C6.34667 11.5 7.16667 12.32 7.16667 13.3333C7.16667 14.3467 6.34667 15.1667 5.33333 15.1667ZM5.33333 12.5C4.87333 12.5 4.5 12.8733 4.5 13.3333C4.5 13.7933 4.87333 14.1667 5.33333 14.1667C5.79333 14.1667 6.16667 13.7933 6.16667 13.3333C6.16667 12.8733 5.79333 12.5 5.33333 12.5Z"
                :fill="color" />
            <path
                d="M10.6666 15.1667C9.65325 15.1667 8.83325 14.3467 8.83325 13.3333C8.83325 12.32 9.65325 11.5 10.6666 11.5C11.6799 11.5 12.4999 12.32 12.4999 13.3333C12.4999 14.3467 11.6799 15.1667 10.6666 15.1667ZM10.6666 12.5C10.2066 12.5 9.83325 12.8733 9.83325 13.3333C9.83325 13.7933 10.2066 14.1667 10.6666 14.1667C11.1266 14.1667 11.4999 13.7933 11.4999 13.3333C11.4999 12.8733 11.1266 12.5 10.6666 12.5Z"
                :fill="color" />
            <path
                d="M14.6667 9.83333H12.6667C12.0267 9.83333 11.5 9.30667 11.5 8.66667V6.66667C11.5 6.02667 12.0267 5.5 12.6667 5.5H13.5267C13.7067 5.5 13.8733 5.59334 13.96 5.75334L15.1 7.75334C15.14 7.82667 15.1667 7.91333 15.1667 8V9.33333C15.1667 9.60667 14.94 9.83333 14.6667 9.83333ZM12.6667 6.5C12.5733 6.5 12.5 6.57333 12.5 6.66667V8.66667C12.5 8.76 12.5733 8.83333 12.6667 8.83333H14.1667V8.13334L13.2333 6.5H12.6667Z"
                :fill="color" />
            <path
                d="M3.32665 6.50008C2.40665 6.50008 1.53999 6.10007 0.953323 5.40007C0.85999 5.30007 0.760001 5.16007 0.673334 5.02673C0.360001 4.5534 0.186668 3.99341 0.173334 3.40674C0.146668 2.4334 0.560009 1.52007 1.30668 0.900065C1.87334 0.433398 2.55331 0.180081 3.27331 0.166748C4.05998 0.173415 4.84668 0.433416 5.43334 0.960083C6.10001 1.54675 6.48667 2.36676 6.50667 3.26009C6.52 3.84009 6.37332 4.41341 6.07999 4.91341C5.91999 5.20008 5.7 5.47342 5.46 5.67342C4.92666 6.18009 4.19332 6.48008 3.41332 6.50008C3.37999 6.50008 3.35332 6.50008 3.32665 6.50008ZM3.32665 1.16675C3.31332 1.16675 3.29999 1.16675 3.28666 1.16675C2.79999 1.17341 2.33331 1.35342 1.93998 1.67342C1.43331 2.09342 1.15333 2.72009 1.16666 3.38009C1.17999 3.78009 1.29333 4.1601 1.50667 4.47343C1.56667 4.56676 1.62665 4.6534 1.69999 4.7334C2.12665 5.24007 2.76 5.50674 3.38 5.49341C3.91333 5.48007 4.40665 5.28009 4.77998 4.92676C4.95332 4.78009 5.09332 4.60674 5.19999 4.41341C5.40665 4.06008 5.50666 3.6734 5.49999 3.28007C5.48666 2.66674 5.22001 2.10674 4.76668 1.70007C4.36668 1.36007 3.85999 1.16675 3.32665 1.16675Z"
                :fill="color" />
            <path
                d="M2.9665 4.49987C2.83984 4.49987 2.71982 4.45319 2.61982 4.35985L1.94648 3.71988C1.74648 3.52655 1.73984 3.21322 1.93318 3.01322C2.12651 2.81322 2.43984 2.80654 2.63984 2.99987L2.9665 3.31318L4.01318 2.29988C4.21318 2.10654 4.52651 2.11318 4.71984 2.31318C4.91317 2.51318 4.90649 2.82656 4.70649 3.01989L3.31314 4.36653C3.21314 4.45319 3.0865 4.49987 2.9665 4.49987Z"
                :fill="color" />
        </svg>
</template>

<script>
export default {
    name:'truck-icon',
    props:{
    size:{
      type:[String,Number],
      default:16
    },
    color:{
      type:String,
      default:'#737373'
    }
  }
}
</script>

<style>

</style>