<template>
    <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.92 19.75C10.63 19.75 10.35 19.71 10.12 19.62C6.7 18.45 1.25 14.27 1.25 8.08997C1.25 4.91997 3.80999 2.34998 6.95999 2.34998C8.46999 2.34998 9.87999 2.92996 10.93 3.95996C11.99 2.92996 13.39 2.34998 14.9 2.34998C18.05 2.34998 20.61 4.91997 20.61 8.08997C20.61 8.51997 20.58 8.95997 20.52 9.40997C20.49 9.63997 20.35 9.84997 20.15 9.96997C19.95 10.09 19.7 10.1 19.48 10.01C18.25 9.47998 16.72 9.87998 15.92 10.95C15.78 11.14 15.51 11.24 15.32 11.25C15.08 11.25 14.86 11.14 14.72 10.95C14.15 10.19 13.28 9.75998 12.34 9.75998C10.71 9.75998 9.38 11.1 9.38 12.75C9.38 15.35 10.87 17.2 12.13 18.31C12.31 18.47 12.4 18.7 12.38 18.94C12.36 19.18 12.23 19.39 12.02 19.52C11.91 19.58 11.82 19.62 11.77 19.63C11.51 19.71 11.22 19.75 10.92 19.75ZM6.95999 3.84998C4.63999 3.84998 2.75 5.74997 2.75 8.08997C2.75 13.32 7.10001 16.54 9.60001 17.78C8.65001 16.51 7.87 14.83 7.87 12.74C7.87 10.26 9.86999 8.24997 12.33 8.24997C13.45 8.24997 14.5 8.65998 15.31 9.38998C16.32 8.48998 17.76 8.07996 19.1 8.32996C19.1 8.24996 19.1 8.16998 19.1 8.09998C19.1 5.75998 17.21 3.85995 14.89 3.85995C13.56 3.85995 12.33 4.47996 11.52 5.54996C11.38 5.73996 11.16 5.84998 10.92 5.84998C10.68 5.84998 10.46 5.73996 10.32 5.54996C9.53001 4.46996 8.29999 3.84998 6.95999 3.84998Z" :fill="color"/>
<path d="M15.3206 21.65C15.0906 21.65 14.8506 21.62 14.6606 21.55C13.8006 21.26 12.4106 20.55 11.1406 19.42C9.66062 18.11 7.89062 15.89 7.89062 12.74C7.89062 10.26 9.89062 8.25 12.3506 8.25C13.4706 8.25 14.5206 8.66001 15.3306 9.39001C16.5906 8.26001 18.5106 7.94 20.0906 8.63C21.7106 9.35 22.7606 10.97 22.7606 12.75C22.7606 17.49 18.6006 20.67 15.9806 21.56C15.7806 21.62 15.5506 21.65 15.3206 21.65ZM12.3406 9.75C10.7106 9.75 9.38062 11.09 9.38062 12.74C9.38062 15.34 10.8706 17.19 12.1306 18.3C13.2406 19.28 14.4106 19.89 15.1406 20.13C15.2206 20.16 15.4106 20.16 15.4806 20.13C16.9806 19.62 21.2506 17.17 21.2506 12.74C21.2506 11.55 20.5506 10.47 19.4806 10C18.2706 9.47 16.7206 9.87 15.9206 10.94C15.7806 11.13 15.5106 11.23 15.3206 11.24C15.0806 11.24 14.8606 11.13 14.7206 10.94C14.1506 10.18 13.2806 9.75 12.3406 9.75Z" :fill="color"/>
    </svg>
  </template>
  
  <script>
  export default {
  name:'lovely-icon',
  props:{
      size:{
        type:[String,Number],
        default:24
      },
      color:{
        type:String,
        default:'#979797'
      }
    }
  }
  </script>
  
  <style>
  
  </style>