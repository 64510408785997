<template>
    <div class="box  p-3 rounded-3">
        <div class="row justify-content-between">
            <div class="col-md-9 t-c">
                <h5>{{ title }}</h5>
                <p>
                    <span class="m-c">
                        {{ department }}
                    </span>
                    -
                    <span style="color: #FFBC00;">
                        {{ category ?? 'N/A' }}
                    </span>
                </p>
            </div>
            <div class="col-md-3 text-start ">
                <slot name="icon">

                </slot>
            </div>

        </div>
        <p class="t-c">
            {{ description }}
        </p>
        <div class="d-flex gap-2  align-items-center">
            <p class="t-c fs-r-12">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12 22.75C6.76 22.75 2.5 18.49 2.5 13.25C2.5 8.01 6.76 3.75 12 3.75C17.24 3.75 21.5 8.01 21.5 13.25C21.5 18.49 17.24 22.75 12 22.75ZM12 5.25C7.59 5.25 4 8.84 4 13.25C4 17.66 7.59 21.25 12 21.25C16.41 21.25 20 17.66 20 13.25C20 8.84 16.41 5.25 12 5.25Z"
                        fill="#979797" />
                    <path
                        d="M12 13.75C11.59 13.75 11.25 13.41 11.25 13V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V13C12.75 13.41 12.41 13.75 12 13.75Z"
                        fill="#979797" />
                    <path
                        d="M15 2.75H9C8.59 2.75 8.25 2.41 8.25 2C8.25 1.59 8.59 1.25 9 1.25H15C15.41 1.25 15.75 1.59 15.75 2C15.75 2.41 15.41 2.75 15 2.75Z"
                        fill="#979797" />
                </svg>
                {{ $t('ends-yet') }} {{ during }}

            </p>
            <p class="t-c fs-r-12">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M17.74 22.75H6.26C3.77 22.75 1.75 20.73 1.75 18.24V11.51C1.75 9.02001 3.77 7 6.26 7H17.74C20.23 7 22.25 9.02001 22.25 11.51V12.95C22.25 13.36 21.91 13.7 21.5 13.7H19.48C19.13 13.7 18.81 13.83 18.58 14.07L18.57 14.08C18.29 14.35 18.16 14.72 18.19 15.1C18.25 15.76 18.88 16.29 19.6 16.29H21.5C21.91 16.29 22.25 16.63 22.25 17.04V18.23C22.25 20.73 20.23 22.75 17.74 22.75ZM6.26 8.5C4.6 8.5 3.25 9.85001 3.25 11.51V18.24C3.25 19.9 4.6 21.25 6.26 21.25H17.74C19.4 21.25 20.75 19.9 20.75 18.24V17.8H19.6C18.09 17.8 16.81 16.68 16.69 15.24C16.61 14.42 16.91 13.61 17.51 13.02C18.03 12.49 18.73 12.2 19.48 12.2H20.75V11.51C20.75 9.85001 19.4 8.5 17.74 8.5H6.26Z"
                        fill="#979797" />
                    <path
                        d="M2.5 13.16C2.09 13.16 1.75 12.82 1.75 12.41V7.84006C1.75 6.35006 2.69 5.00001 4.08 4.47001L12.02 1.47001C12.84 1.16001 13.75 1.27005 14.46 1.77005C15.18 2.27005 15.6 3.08005 15.6 3.95005V7.75003C15.6 8.16003 15.26 8.50003 14.85 8.50003C14.44 8.50003 14.1 8.16003 14.1 7.75003V3.95005C14.1 3.57005 13.92 3.22003 13.6 3.00003C13.28 2.78003 12.9 2.73003 12.54 2.87003L4.6 5.87003C3.79 6.18003 3.24 6.97006 3.24 7.84006V12.41C3.25 12.83 2.91 13.16 2.5 13.16Z"
                        fill="#979797" />
                    <path
                        d="M19.6005 17.7999C18.0905 17.7999 16.8105 16.6799 16.6905 15.2399C16.6105 14.4099 16.9105 13.5999 17.5105 13.0099C18.0205 12.4899 18.7205 12.2 19.4705 12.2H21.5505C22.5405 12.23 23.3005 13.0099 23.3005 13.9699V16.03C23.3005 16.99 22.5405 17.7699 21.5805 17.7999H19.6005ZM21.5305 13.7H19.4805C19.1305 13.7 18.8105 13.8299 18.5805 14.0699C18.2905 14.3499 18.1505 14.7299 18.1905 15.1099C18.2505 15.7699 18.8805 16.2999 19.6005 16.2999H21.5605C21.6905 16.2999 21.8105 16.18 21.8105 16.03V13.9699C21.8105 13.8199 21.6905 13.71 21.5305 13.7Z"
                        fill="#979797" />
                    <path
                        d="M14 12.75H7C6.59 12.75 6.25 12.41 6.25 12C6.25 11.59 6.59 11.25 7 11.25H14C14.41 11.25 14.75 11.59 14.75 12C14.75 12.41 14.41 12.75 14 12.75Z"
                        fill="#979797" />
                </svg>
                {{ price }} {{ $t('riyals') }}
            </p>
            <p class="t-c fs-r-12">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M9.98959 22.75C9.78959 22.75 9.62959 22.71 9.50959 22.66C9.10959 22.51 8.42959 22.02 8.42959 20.47V14.02H6.08959C4.74959 14.02 4.26959 13.39 4.09959 13.02C3.92959 12.64 3.77959 11.87 4.65959 10.86L12.2296 2.26C13.2496 1.1 14.0796 1.18 14.4796 1.33C14.8796 1.48 15.5596 1.97 15.5596 3.52V9.97H17.8996C19.2396 9.97 19.7196 10.6 19.8896 10.97C20.0596 11.35 20.2096 12.12 19.3296 13.13L11.7596 21.73C11.0496 22.54 10.4296 22.75 9.98959 22.75ZM13.9296 2.74C13.8996 2.78 13.6896 2.88 13.3596 3.26L5.78959 11.86C5.50959 12.18 5.46959 12.38 5.46959 12.42C5.48959 12.43 5.66959 12.53 6.08959 12.53H9.17959C9.58959 12.53 9.92959 12.87 9.92959 13.28V20.48C9.92959 20.98 10.0196 21.2 10.0596 21.26C10.0896 21.22 10.2996 21.12 10.6296 20.74L18.1996 12.14C18.4796 11.82 18.5196 11.62 18.5196 11.58C18.4996 11.57 18.3196 11.47 17.8996 11.47H14.8096C14.3996 11.47 14.0596 11.13 14.0596 10.72V3.52C14.0696 3.02 13.9696 2.81 13.9296 2.74Z"
                        fill="#979797" />
                </svg>
                {{ offers }} {{ $t('offers') }}
            </p>
            <p class="t-c fs-r-12">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11.9999 14.17C9.86988 14.17 8.12988 12.44 8.12988 10.3C8.12988 8.16 9.86988 6.44 11.9999 6.44C14.1299 6.44 15.8699 8.17 15.8699 10.31C15.8699 12.45 14.1299 14.17 11.9999 14.17ZM11.9999 7.94C10.6999 7.94 9.62988 9 9.62988 10.31C9.62988 11.62 10.6899 12.68 11.9999 12.68C13.3099 12.68 14.3699 11.62 14.3699 10.31C14.3699 9 13.2999 7.94 11.9999 7.94Z"
                        fill="#979797" />
                    <path
                        d="M11.9997 22.76C10.5197 22.76 9.02969 22.2 7.86969 21.09C4.91969 18.25 1.65969 13.72 2.88969 8.33C3.99969 3.44 8.26969 1.25 11.9997 1.25C11.9997 1.25 11.9997 1.25 12.0097 1.25C15.7397 1.25 20.0097 3.44 21.1197 8.34C22.3397 13.73 19.0797 18.25 16.1297 21.09C14.9697 22.2 13.4797 22.76 11.9997 22.76ZM11.9997 2.75C9.08969 2.75 5.34969 4.3 4.35969 8.66C3.27969 13.37 6.23969 17.43 8.91969 20C10.6497 21.67 13.3597 21.67 15.0897 20C17.7597 17.43 20.7197 13.37 19.6597 8.66C18.6597 4.3 14.9097 2.75 11.9997 2.75Z"
                        fill="#979797" />
                </svg>
                {{ $t(place) }}
            </p>

        </div>
        <div class="d-flex gap-2 align-items-center">
            <p class="t-c opacity-50 fs-r-12">
                <span>تاريخ النشر :</span> {{ datePublish }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'job-card',
    props: {
        title: {
            type: String,
        },
        department: {
            type: String,
        },
        category: {
            type: String,
        },
        during: {
            type: String,
        },
        description: {
            type: String,
        },
        price: {
            type: [String, Number],
        },
        offers: {
            type: [String, Number],
        },
        place: {
            type: String,
        },
        datePublish: {
            type: String,
        }

    }
}
</script>

<style></style>