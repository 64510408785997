<template>
    <div class="rate-experience ">
        <div @click="openDialog" class="rate-experience-wrapper">
            <div class="rate-experience-star">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12.6399 27.5874C11.9733 27.5874 11.5066 27.3607 11.2266 27.1474C10.6933 26.7607 9.8666 25.7607 10.3866 23.5207L11.1466 20.2407C11.2132 19.9607 11.0666 19.4541 10.8666 19.2407L8.21326 16.5874C6.81326 15.1874 6.93325 13.9741 7.11992 13.3741C7.30658 12.7741 7.91991 11.7207 9.86658 11.3874L13.2666 10.8274C13.5199 10.7874 13.9333 10.4807 14.0399 10.2541L15.9199 6.49406C16.8266 4.68073 18.0266 4.41406 18.6666 4.41406C19.3066 4.41406 20.5066 4.68073 21.4133 6.49406L23.2933 10.2541C23.4133 10.4807 23.8132 10.7874 24.0666 10.8274L27.4666 11.3874C29.4133 11.7074 30.0266 12.7741 30.2133 13.3741C30.3999 13.9741 30.5199 15.1874 29.1199 16.5874L26.4666 19.2407C26.2666 19.4407 26.1199 19.9607 26.1866 20.2407L26.9466 23.5207C27.4666 25.7741 26.6399 26.7607 26.1066 27.1474C25.5732 27.5341 24.3866 28.0274 22.3866 26.8407L19.1999 24.9474C18.9332 24.7874 18.3732 24.7874 18.1066 24.9474L14.9199 26.8407C13.9999 27.4007 13.2266 27.5874 12.6399 27.5874ZM18.6666 6.41406C18.4533 6.41406 18.0666 6.66739 17.7066 7.38739L15.8266 11.1474C15.4266 11.9607 14.4799 12.6541 13.5866 12.8007L10.1866 13.3607C9.42661 13.4941 9.07993 13.7741 9.01327 13.9874C8.9466 14.2007 9.06658 14.6274 9.61324 15.1741L12.2666 17.8274C12.9466 18.5074 13.3066 19.7474 13.0933 20.6807L12.3333 23.9607C12.1066 24.9074 12.2532 25.4141 12.3999 25.5341C12.5466 25.6407 13.0799 25.6274 13.9066 25.1207L17.1066 23.2274C17.9999 22.6941 19.3466 22.6941 20.2266 23.2274L23.4133 25.1207C24.2533 25.6141 24.7866 25.6407 24.9333 25.5341C25.0799 25.4274 25.2266 24.9207 24.9999 23.9607L24.2399 20.6807C24.0266 19.7341 24.3732 18.5074 25.0666 17.8274L27.7199 15.1741C28.2666 14.6274 28.3866 14.1874 28.3199 13.9874C28.2532 13.7874 27.9066 13.4941 27.1466 13.3607L23.7466 12.8007C22.8533 12.6541 21.9066 11.9607 21.5066 11.1474L19.6266 7.38739C19.2666 6.66739 18.8799 6.41406 18.6666 6.41406Z"
                        fill="#979797" />
                    <path
                        d="M10.667 7.66602H2.66699C2.12033 7.66602 1.66699 7.21268 1.66699 6.66602C1.66699 6.11935 2.12033 5.66602 2.66699 5.66602H10.667C11.2137 5.66602 11.667 6.11935 11.667 6.66602C11.667 7.21268 11.2137 7.66602 10.667 7.66602Z"
                        fill="#979797" />
                    <path
                        d="M6.66699 26.334H2.66699C2.12033 26.334 1.66699 25.8807 1.66699 25.334C1.66699 24.7873 2.12033 24.334 2.66699 24.334H6.66699C7.21366 24.334 7.66699 24.7873 7.66699 25.334C7.66699 25.8807 7.21366 26.334 6.66699 26.334Z"
                        fill="#979797" />
                    <path
                        d="M4.00033 17H2.66699C2.12033 17 1.66699 16.5467 1.66699 16C1.66699 15.4533 2.12033 15 2.66699 15H4.00033C4.54699 15 5.00033 15.4533 5.00033 16C5.00033 16.5467 4.54699 17 4.00033 17Z"
                        fill="#979797" />
                </svg>
            </div>
            <div class="rate-experience-text">
                {{ $t('rate-your-experience') }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'rate-experience-btn',
    props: {
        group: {
            type: String,
            require: true
        }
    },
    methods: {
        openDialog() {
            this.fireOpenDialog(this.group)
        }
    }
}
</script>

<style>
.rate-experience {
    cursor: pointer;
    position: fixed;
    left: 0;
    right: auto;
    border-radius: 0px 8px 8px 0px;
    width: 56px;
    height: 112px;
    background: #CDD7D8;
    top: calc(100vh / 2 - 76px);
    z-index: 999999;

}

html[dir=ltr] .rate-experience {
    right: 0;
    left: auto;
    border-radius: 8px 0px 0px 8px;
}

.rate-experience-wrapper {
    padding: 10px;
    text-align: center;
}

.rate-experience-text {
    font-size: 16px;
    color: #979797;
}

@media screen and (max-width: 600px) {
    .rate-experience {
        height: auto;
        width: 30px;
    }

    .rate-experience-wrapper {
        padding: 2px;

    }

    .rate-experience-star svg {
        height: 24px;
        width: 24px;
    }

    .rate-experience-text {
        display: none;
        width: 0px;
        padding: 0;
        margin: 0;
    }
}
</style>