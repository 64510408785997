<template>
  <svg :width="size" :height="size" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.9585 28.8391C17.3852 28.8391 15.1585 27.7325 13.3985 22.4391L12.4385 19.5591L9.55854 18.5991C4.27854 16.8391 3.17188 14.6125 3.17188 13.0391C3.17188 11.4791 4.27854 9.23912 9.55854 7.46579L20.8785 3.69245C23.7052 2.74578 26.0652 3.02578 27.5185 4.46578C28.9719 5.90579 29.2519 8.27912 28.3052 11.1058L24.5319 22.4258C22.7585 27.7325 20.5319 28.8391 18.9585 28.8391ZM10.1852 9.37245C6.47854 10.6125 5.15854 12.0791 5.15854 13.0391C5.15854 13.9991 6.47854 15.4658 10.1852 16.6925L13.5452 17.8125C13.8385 17.9058 14.0785 18.1458 14.1719 18.4391L15.2919 21.7991C16.5185 25.5058 17.9985 26.8258 18.9585 26.8258C19.9185 26.8258 21.3852 25.5058 22.6252 21.7991L26.3985 10.4791C27.0785 8.42579 26.9585 6.74578 26.0919 5.87912C25.2252 5.01245 23.5452 4.90579 21.5052 5.58579L10.1852 9.37245Z" :fill="color"/>
                                <path d="M13.481 19.1993C13.2277 19.1993 12.9744 19.106 12.7744 18.906C12.3877 18.5193 12.3877 17.8793 12.7744 17.4927L17.5477 12.706C17.9344 12.3193 18.5744 12.3193 18.961 12.706C19.3477 13.0927 19.3477 13.7327 18.961 14.1193L14.1877 18.906C14.001 19.106 13.7344 19.1993 13.481 19.1993Z" :fill="color"/>
                                </svg>
</template>

<script>
export default {
    name:'send-icon',
    props:{
      size:{
        type:[String,Number],
        default:32
      },
      color:{
        type:String,
        default:'white'
      }
    }
}
</script>

<style>

</style>