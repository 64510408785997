<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.81054 20.2036C5.41438 18.8623 4.95639 16.9148 4.70167 15.0266C4.37031 12.5831 6.79585 10.6931 9.14363 11.5685L11.689 12.517C8.35715 14.5211 11.1852 18.269 10.1092 20.9116C9.69214 21.9352 8.50291 22.4251 7.44142 22.049C6.34106 21.6588 4.77962 21.1046 3.91789 20.7994C3.65875 20.7064 3.60246 20.3774 3.81054 20.2036Z" :fill="color" fill-opacity="0.5"/>
<path d="M20.6256 3.8437C19.0218 5.18507 19.4797 7.13258 19.7345 9.02073C20.0658 11.4642 17.6392 13.3532 15.2925 12.4788L12.7472 11.5303C16.0779 9.52514 13.2499 5.77721 14.3259 3.13466C14.7429 2.11106 15.9322 1.62111 16.9936 1.99727C18.094 2.3874 19.6555 2.94166 20.5172 3.24681C20.7763 3.33987 20.8337 3.66997 20.6256 3.8437Z" :fill="color" fill-opacity="0.5"/>
<path d="M20.5302 20.3101C19.1533 18.7191 17.1766 19.1532 15.2595 19.3868C12.7797 19.6919 10.8376 17.2875 11.7042 14.9856L12.6445 12.4889C14.7127 15.7859 18.4983 13.0364 21.1931 14.1197C22.2374 14.5393 22.7462 15.7136 22.3722 16.7556C21.9867 17.8336 21.4373 19.3648 21.1348 20.2098C21.0437 20.4629 20.7086 20.5163 20.5302 20.3101Z" :fill="color" fill-opacity="0.5"/>
<path d="M3.90587 3.7678C5.28492 5.35885 7.25944 4.92471 9.17653 4.69111C11.6574 4.38711 13.5995 6.79143 12.7318 9.09229L11.7905 11.5879C9.72219 8.29301 5.93774 11.0415 3.2418 9.95919C2.19752 9.53965 1.68877 8.36526 2.0616 7.32434C2.44827 6.2453 2.99764 4.71412 3.30008 3.86909C3.39234 3.61499 3.72628 3.56256 3.90587 3.7678Z" :fill="color" fill-opacity="0.5"/>
</svg>

</template>

<script>
export default {
    name:'flower-icon',
  props:{
    size:{
      type:[String,Number],
      default:24
    },
    color:{
      type:String,
      default:'#1FB9B3'
    },
}
}
</script>

<style>

</style>