export default{
    SET_IS_MOBILE(state,payload){
        state.isMobile = payload
    },
    SET_IS_TABLET(state,payload){
        state.isTablet = payload
    },
    SET_IS_DESKTOP(state,payload){
        state.isDesktop = payload
    },
    SET_LOADING(state,payload){
        state.loading = payload
    },

}