<template>
    <div class="input-range-time-picker">
        <div class="input-wrapper position-relative">
            <img class="stopwatch-icon position-absolute" src="@/assets/af-icons/stopwatch-solid.svg" alt="stopwatch-icon" />
            <input type="text" :value="formattedTimeRange" placeholder="حدد التوقيت" readonly class="form-control" style="display: block;" @click="showTimePicker = !showTimePicker" />
        </div>

        <vc-date-picker 
            v-if="showTimePicker"
            v-model="range" 
            :mode="mode" 
            is-range 
            :locale="$i18n.locale"
            :style="{ 'text-align': $i18n.locale == 'ar' ? 'rtl' : 'ltr' }"
            style="z-index: 999"
            class="mt-1 position-absolute"
        ></vc-date-picker>

    </div>
</template>


<script>
export default {
    name: "input-range-time-picker",
    emits: ["updateValue"],
    data(vm) {
    return {
        mode: "time",
        showTimePicker: false,
      range: {
        start: null,
        end: null,
      },
      modelConfig: {
        type: 'string',
        mask: vm.mask,
        start: {
          timeAdjust: vm.mode == 'date' ? '00:00:00' : undefined,
          dateAdjust: vm.mode == 'time' ? vm.dateToString(new Date()) : undefined
        },
        end: {
          timeAdjust: vm.mode == 'date' ? '23:59:59' : undefined,
          dateAdjust: vm.mode == 'time' ? vm.dateToString(new Date()) : undefined
        },

      },
    };
  },

  computed: {
    formattedTimeRange() {
      if (this.range.start && this.range.end) {
        return `${this.formatTime(this.range.start, true)} - ${this.formatTime(this.range.end, true)}`;
      } else {
        return '';
      }
    }
  },

  methods: {
    formatTime(time, isAmPm = false) {
      const d = new Date(time);
      let hours = String(d.getHours()).padStart(2, '0');
      const minutes = String(d.getMinutes()).padStart(2, '0');

      if (isAmPm) {
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        const strTime = `${String(hours).padStart(2, '0')}:${minutes} ${ampm}`;
        return strTime;
      }

      return `${hours}:${minutes}`;
    }
  },

  watch: {
    range: {
      handler(val) {
        if (val.start != null && val.end != null) {
            // this.showTimePicker = false;
            this.$emit("updateValue", {start: this.formatTime(val.start, false), end: this.formatTime(val.end, false)});
        }
      },
      deep: true
    }
  }
}
</script>



<style lang="scss" scoped>
.input-range-time-picker {
    .input-wrapper {
        .stopwatch-icon {
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
</style>