<template>
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.44 28.4533C14.9866 28.4533 13.5333 27.9066 12.4266 26.8C10.2133 24.5866 10.2133 21 12.4266 18.7866L15.7333 15.4933C16.12 15.1066 16.76 15.1066 17.1466 15.4933C17.5333 15.88 17.5333 16.52 17.1466 16.9066L13.84 20.2C12.4133 21.6266 12.4133 23.96 13.84 25.3866C15.2666 26.8133 17.6 26.8133 19.0266 25.3866L24.2133 20.2C25.7866 18.6266 26.6533 16.5333 26.6533 14.3066C26.6533 12.08 25.7866 9.98665 24.2133 8.41331C20.96 5.15998 15.68 5.15998 12.4266 8.41331L6.7733 14.0666C5.4533 15.3866 4.71997 17.1466 4.71997 19.0133C4.71997 20.88 5.4533 22.64 6.7733 23.96C7.15997 24.3466 7.15997 24.9866 6.7733 25.3733C6.38664 25.76 5.74664 25.76 5.35997 25.3733C3.66664 23.6666 2.71997 21.4133 2.71997 19.0133C2.71997 16.6133 3.6533 14.3466 5.35997 12.6533L11.0133 6.99998C15.04 2.97331 21.6 2.97331 25.6266 6.99998C27.5733 8.94665 28.6533 11.5466 28.6533 14.3066C28.6533 17.0666 27.5733 19.6666 25.6266 21.6133L20.44 26.8C19.3333 27.9066 17.8933 28.4533 16.44 28.4533Z" fill="#2CB7B3"/>
</svg>
</template>

<script>
export default {

}
</script>

<style>

</style>