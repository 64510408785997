
<template>
    <div class="chips-container d-flex">
        <div 
            v-for="(chip, index) in chips" 
            :key="index" 
             
            :class="['chip d-flex align-items-center justify-content-center', { 'selected': chip.id === selectedChip }]" 
            @click="selectChip(chip.id)"
        >
            {{ chip.name }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'choice-chips',
    props: { 
        chips: {
            type: Array,
            required: true
        },
        value: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            selectedChip: this.value
        };
    },
    methods: {
        selectChip(chipId) {
            
            this.selectedChip = chipId;
            this.$emit('input', chipId);
            this.$emit('change', chipId);
        }
    },
    watch: {
        value(newValue) {
            this.selectedChip = newValue;
        }
    }
};
</script>

<style scoped>
.chips-container{
    gap:28px;
}
.chip {
    padding: 10px 20px;
    margin: 5px;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    box-shadow: 0 0 5px rgba(0, 0, 0, .15);
    flex:1;
    height: 60px;
    text-align: center;
    
}
.chip:hover,
.chip.selected {
    background-color: #1FB9B3;
    color: white;
}
</style>
 
