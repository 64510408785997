<template>
  <div class="mail-subscribe__section">
    <div class="container h-100">
      <div class="row h-100 align-items-center">
        <div class="col-12 col-lg-6">
          <div class="section-text text-white">
            <h4 class="heading">اشتركي بالقائمة البريدية</h4>
            <p class="subheading">ليصلك كل العروض والاخبار الجديدة</p>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="section-form">
            <form class="d-flex justify-content-end">
              <b-form-input class="form-input" type="email" placeholder="أكتبي بريدك الالكتروني" />
              <button  class="subscribe-btn btn">أشترك</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "NetworkLayoutMailSubscribeSection",
};
</script>



<style lang="scss" scoped>
.mail-subscribe__section {
  height: 200px;
  background: linear-gradient(-45deg,#1FB9B3,#FFFFFF);
  margin-top: 74px;
  width: 90%;
  margin: 20px auto;
  border-radius: 12px;

  .section-text {
    .heading {
      font-size: 48px;
      font-weight: 600;
      line-height: 72px;
      margin-bottom: 1rem;
    }

    .subheading {
      font-size: 20px;
      font-weight: 400;
      color: #F6F8F9;
    }
  }

  .section-form {
    gap: 10px;
    .form-input {
      width: 385px !important;
      height: 72px !important;
      border: 1px solid #E6E6E6 !important;
      border-radius: 12px !important;
      font-size: 24px;
      padding-inline-start: 25px;
      &::placeholder {
        color: #BCBCBC !important;
      }
    }

    .subscribe-btn {
      width: 140px;
      height: 72px;
      background-color: #CF1F3E;
      font-size: 24px;
      font-weight: 400;
      color: #fff;
      border-radius: 12px;
      margin: 0 10px;
    }
  }

}
</style>
