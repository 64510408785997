<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M19.6932 26.3333H12.3465C11.7598 26.3333 11.0132 26.2666 10.5198 25.76C10.0398 25.2666 10.0532 24.6533 10.0665 24.24V23.0533C6.7065 20.8133 4.49316 16.92 4.49316 13.2133C4.49316 9.70665 6.0665 6.42665 8.79983 4.22665C11.5332 2.02665 15.1198 1.19998 18.6265 1.97331C21.9598 2.70665 24.8398 4.93331 26.3332 7.93331C29.2265 13.76 26.4398 20.0666 21.9865 23.0666V24.0666C21.9998 24.4533 22.0132 25.16 21.4665 25.72C21.0532 26.12 20.4665 26.3333 19.6932 26.3333ZM12.0532 24.32C12.1198 24.32 12.2132 24.3333 12.3332 24.3333H19.6932C19.8265 24.3333 19.9198 24.32 19.9732 24.3066C19.9732 24.2666 19.9732 24.2133 19.9732 24.1733V22.4933C19.9732 22.1466 20.1598 21.8133 20.4532 21.64C24.3998 19.2533 27.0132 13.8 24.5198 8.79998C23.2932 6.33331 20.9198 4.50665 18.1732 3.90665C15.2665 3.26665 12.2932 3.94665 10.0265 5.77331C7.75983 7.59998 6.4665 10.3066 6.4665 13.2133C6.4665 16.92 9.0265 20.12 11.5598 21.6533C11.8665 21.84 12.0398 22.16 12.0398 22.5066V24.3066C12.0532 24.3066 12.0532 24.3066 12.0532 24.32Z"
                            fill="#1FB9B3" />
                        <path
                            d="M20.6664 30.3334C20.573 30.3334 20.4797 30.32 20.3864 30.2934C17.5064 29.4667 14.4797 29.4667 11.5997 30.2934C11.0664 30.44 10.5197 30.1334 10.3597 29.6C10.1997 29.0667 10.5197 28.52 11.053 28.36C14.2797 27.44 17.7064 27.44 20.933 28.36C21.4664 28.5067 21.773 29.0667 21.6264 29.6C21.5064 30.0534 21.1064 30.3334 20.6664 30.3334Z"
                            fill="#1FB9B3" />
</svg>
</template>

<script>
export default {

}
</script>

<style>

</style>