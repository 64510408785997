<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
        <path
            d="M21 23.0391H3C2.59 23.0391 2.25 22.6991 2.25 22.2891C2.25 21.8791 2.59 21.5391 3 21.5391H21C21.41 21.5391 21.75 21.8791 21.75 22.2891C21.75 22.6991 21.41 23.0391 21 23.0391Z"
            :fill="color" />
        <path
            d="M21 3.03906H3C2.59 3.03906 2.25 2.69906 2.25 2.28906C2.25 1.87906 2.59 1.53906 3 1.53906H21C21.41 1.53906 21.75 1.87906 21.75 2.28906C21.75 2.69906 21.41 3.03906 21 3.03906Z"
            :fill="color" />
        <path
            d="M12 19.0391C11.59 19.0391 11.25 18.6991 11.25 18.2891V6.28906C11.25 5.87906 11.59 5.53906 12 5.53906C12.41 5.53906 12.75 5.87906 12.75 6.28906V18.2891C12.75 18.6991 12.41 19.0391 12 19.0391Z"
            :fill="color" />
        <path
            d="M14.8294 8.75945C14.6394 8.75945 14.4494 8.68945 14.2994 8.53945L11.9994 6.23945L9.69937 8.53945C9.40937 8.82945 8.92937 8.82945 8.63938 8.53945C8.34938 8.24945 8.34938 7.76945 8.63938 7.47945L11.4694 4.64945C11.7494 4.36945 12.2494 4.36945 12.5294 4.64945L15.3594 7.47945C15.6494 7.76945 15.6494 8.24945 15.3594 8.53945C15.2094 8.68945 15.0194 8.75945 14.8294 8.75945Z"
            :fill="color" />
        <path
            d="M11.9994 19.7591C11.7994 19.7591 11.6094 19.6791 11.4694 19.5391L8.63938 16.7091C8.34938 16.4191 8.34938 15.9391 8.63938 15.6491C8.92937 15.3591 9.40937 15.3591 9.69937 15.6491L11.9994 17.9491L14.2994 15.6491C14.5894 15.3591 15.0694 15.3591 15.3594 15.6491C15.6494 15.9391 15.6494 16.4191 15.3594 16.7091L12.5294 19.5391C12.3894 19.6791 12.1994 19.7591 11.9994 19.7591Z"
            :fill="color" />
    </svg>
</template>
  
<script>
export default {
    name: 'empty-wallet',
    props: {
        size: {
            type: [String, Number],
            default: 32
        },
        color: {
            type: String,
            default: '#979797'
        }
    }
}
</script>
  
<style></style>
  