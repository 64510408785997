<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
        <path
            d="M18.0003 8.19884C17.9703 8.19884 17.9503 8.19884 17.9203 8.19884H17.8703C15.9803 8.13884 14.5703 6.67883 14.5703 4.87883C14.5703 3.03883 16.0703 1.54883 17.9003 1.54883C19.7303 1.54883 21.2303 3.04883 21.2303 4.87883C21.2203 6.68883 19.8103 8.14883 18.0103 8.20883C18.0103 8.19883 18.0103 8.19884 18.0003 8.19884ZM17.9003 3.03883C16.8903 3.03883 16.0703 3.85884 16.0703 4.86884C16.0703 5.85884 16.8403 6.65884 17.8303 6.69884C17.8403 6.68884 17.9203 6.68884 18.0103 6.69884C18.9803 6.64884 19.7303 5.84884 19.7403 4.86884C19.7403 3.85884 18.9203 3.03883 17.9003 3.03883Z"
            :fill="color" />
        <path
            d="M18.0085 15.5697C17.6185 15.5697 17.2285 15.5397 16.8385 15.4697C16.4285 15.3997 16.1585 15.0097 16.2285 14.5997C16.2985 14.1897 16.6885 13.9197 17.0985 13.9897C18.3285 14.1997 19.6285 13.9697 20.4985 13.3897C20.9685 13.0797 21.2185 12.6897 21.2185 12.2997C21.2185 11.9097 20.9585 11.5297 20.4985 11.2197C19.6285 10.6397 18.3085 10.4097 17.0685 10.6297C16.6585 10.7097 16.2685 10.4297 16.1985 10.0197C16.1285 9.6097 16.3985 9.2197 16.8085 9.1497C18.4385 8.8597 20.1285 9.16969 21.3285 9.96969C22.2085 10.5597 22.7185 11.3997 22.7185 12.2997C22.7185 13.1897 22.2185 14.0397 21.3285 14.6397C20.4185 15.2397 19.2385 15.5697 18.0085 15.5697Z"
            :fill="color" />
        <path
            d="M5.96656 8.19907C5.95656 8.19907 5.94656 8.19907 5.94656 8.19907C4.14656 8.13907 2.73656 6.67906 2.72656 4.87906C2.72656 3.03906 4.22656 1.53906 6.05656 1.53906C7.88656 1.53906 9.38656 3.03906 9.38656 4.86906C9.38656 6.67906 7.97656 8.13907 6.17656 8.19907L5.96656 7.44907L6.03656 8.19907C6.01656 8.19907 5.98656 8.19907 5.96656 8.19907ZM6.06656 6.69907C6.12656 6.69907 6.17656 6.69906 6.23656 6.70906C7.12656 6.66906 7.90656 5.86906 7.90656 4.87906C7.90656 3.86906 7.08657 3.04906 6.07656 3.04906C5.06656 3.04906 4.24656 3.86906 4.24656 4.87906C4.24656 5.85906 5.00656 6.64906 5.97656 6.70906C5.98656 6.69906 6.02656 6.69907 6.06656 6.69907Z"
            :fill="color" />
        <path
            d="M5.96 15.5697C4.73 15.5697 3.55 15.2397 2.64 14.6397C1.76 14.0497 1.25 13.1997 1.25 12.2997C1.25 11.4097 1.76 10.5597 2.64 9.96969C3.84 9.16969 5.53 8.8597 7.16 9.1497C7.57 9.2197 7.84 9.6097 7.77 10.0197C7.7 10.4297 7.31 10.7097 6.9 10.6297C5.66 10.4097 4.35 10.6397 3.47 11.2197C3 11.5297 2.75 11.9097 2.75 12.2997C2.75 12.6897 3.01 13.0797 3.47 13.3897C4.34 13.9697 5.64 14.1997 6.87 13.9897C7.28 13.9197 7.67 14.1997 7.74 14.5997C7.81 15.0097 7.54 15.3997 7.13 15.4697C6.74 15.5397 6.35 15.5697 5.96 15.5697Z"
            :fill="color" />
        <path
            d="M12.0003 15.6695C11.9703 15.6695 11.9503 15.6695 11.9203 15.6695H11.8703C9.98031 15.6095 8.57031 14.1495 8.57031 12.3495C8.57031 10.5095 10.0703 9.01953 11.9003 9.01953C13.7303 9.01953 15.2303 10.5195 15.2303 12.3495C15.2203 14.1595 13.8103 15.6195 12.0103 15.6795C12.0103 15.6695 12.0103 15.6695 12.0003 15.6695ZM11.9003 10.5095C10.8903 10.5095 10.0703 11.3295 10.0703 12.3395C10.0703 13.3295 10.8403 14.1295 11.8303 14.1695C11.8403 14.1595 11.9203 14.1595 12.0103 14.1695C12.9803 14.1195 13.7303 13.3195 13.7403 12.3395C13.7403 11.3395 12.9203 10.5095 11.9003 10.5095Z"
            :fill="color" />
        <path
            d="M12.0013 23.0498C10.8013 23.0498 9.60125 22.7398 8.67125 22.1098C7.79125 21.5198 7.28125 20.6798 7.28125 19.7798C7.28125 18.8898 7.78125 18.0298 8.67125 17.4398C10.5412 16.1998 13.4713 16.1998 15.3313 17.4398C16.2113 18.0298 16.7213 18.8698 16.7213 19.7698C16.7213 20.6598 16.2213 21.5198 15.3313 22.1098C14.4013 22.7298 13.2013 23.0498 12.0013 23.0498ZM9.50125 18.6998C9.03125 19.0098 8.78125 19.3998 8.78125 19.7898C8.78125 20.1798 9.04125 20.5598 9.50125 20.8698C10.8513 21.7798 13.1413 21.7798 14.4913 20.8698C14.9613 20.5598 15.2113 20.1698 15.2113 19.7798C15.2113 19.3898 14.9513 19.0098 14.4913 18.6998C13.1513 17.7898 10.8613 17.7998 9.50125 18.6998Z"
            :fill="color" />
    </svg>
</template>
  
<script>
export default {
    name: 'empty-wallet',
    props: {
        size: {
            type: [String, Number],
            default: 32
        },
        color: {
            type: String,
            default: '#979797'
        }
    }
}
</script>
  
<style></style>
  