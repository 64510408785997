<template>
    <div class="box">

        <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex">
                <p style="width: fit-content;" class="rounded-1 px-2 py-1 text-white" :class="statusClass">
                    {{ statusName }}
                </p>
                <h4 class="box-title m-c mx-2"
                    @click="router_push('service-provider-request-purchase-service-show', { id: itemId })">
                    {{ service ?? 'N/A' }}

                </h4>
            </div>
            <div>
                <p class="t-c">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
                            fill="#737373" />
                        <path
                            d="M15.71 15.93C15.58 15.93 15.45 15.9 15.33 15.82L12.23 13.97C11.46 13.51 10.89 12.5 10.89 11.61V7.51001C10.89 7.10001 11.23 6.76001 11.64 6.76001C12.05 6.76001 12.39 7.10001 12.39 7.51001V11.61C12.39 11.97 12.69 12.5 13 12.68L16.1 14.53C16.46 14.74 16.57 15.2 16.36 15.56C16.21 15.8 15.96 15.93 15.71 15.93Z"
                            fill="#737373" />
                    </svg>
                    تاريخ طلبك : {{ dateRequest }}
                </p>
            </div>


        </div>
        <div class="d-flex gap-2 flex-wrap">
            <p class="t-c">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10.0002 15.1666H6.00018C5.12018 15.1666 4.38685 15.0799 3.76685 14.8933C3.54018 14.8266 3.39351 14.6066 3.40685 14.3733C3.57351 12.3799 5.59351 10.8132 8.00018 10.8132C10.4068 10.8132 12.4202 12.3733 12.5935 14.3733C12.6135 14.6133 12.4668 14.8266 12.2335 14.8933C11.6135 15.0799 10.8802 15.1666 10.0002 15.1666ZM4.48018 14.0399C4.92018 14.1266 5.42018 14.1666 6.00018 14.1666H10.0002C10.5802 14.1666 11.0802 14.1266 11.5202 14.0399C11.1668 12.7599 9.70684 11.8132 8.00018 11.8132C6.29351 11.8132 4.83351 12.7599 4.48018 14.0399Z"
                        fill="#737373" />
                    <path
                        d="M9.99992 1.33325H5.99992C2.66659 1.33325 1.33325 2.66659 1.33325 5.99992V9.99992C1.33325 12.5199 2.09325 13.8999 3.90659 14.4132C4.05325 12.6799 5.83325 11.3132 7.99992 11.3132C10.1666 11.3132 11.9466 12.6799 12.0933 14.4132C13.9066 13.8999 14.6666 12.5199 14.6666 9.99992V5.99992C14.6666 2.66659 13.3333 1.33325 9.99992 1.33325ZM7.99992 9.44657C6.67992 9.44657 5.61325 8.37326 5.61325 7.05326C5.61325 5.73326 6.67992 4.66659 7.99992 4.66659C9.31992 4.66659 10.3866 5.73326 10.3866 7.05326C10.3866 8.37326 9.31992 9.44657 7.99992 9.44657Z"
                        stroke="#737373" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                        d="M7.99995 9.94674C6.40662 9.94674 5.11328 8.64676 5.11328 7.05343C5.11328 5.46009 6.40662 4.16675 7.99995 4.16675C9.59328 4.16675 10.8866 5.46009 10.8866 7.05343C10.8866 8.64676 9.59328 9.94674 7.99995 9.94674ZM7.99995 5.16675C6.95995 5.16675 6.11328 6.01343 6.11328 7.05343C6.11328 8.10009 6.95995 8.94674 7.99995 8.94674C9.03995 8.94674 9.88662 8.10009 9.88662 7.05343C9.88662 6.01343 9.03995 5.16675 7.99995 5.16675Z"
                        fill="#737373" />
                </svg>
                {{ name }}
            </p>
            <p class="t-c">
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M8.11931 9.44508C6.69931 9.44508 5.53931 8.29175 5.53931 6.86508C5.53931 5.43841 6.69931 4.29175 8.11931 4.29175C9.53931 4.29175 10.6993 5.44508 10.6993 6.87175C10.6993 8.29842 9.53931 9.44508 8.11931 9.44508ZM8.11931 5.29175C7.25264 5.29175 6.53931 5.99841 6.53931 6.87175C6.53931 7.74508 7.24597 8.45175 8.11931 8.45175C8.99264 8.45175 9.69931 7.74508 9.69931 6.87175C9.69931 5.99841 8.98597 5.29175 8.11931 5.29175Z"
                        fill="#737373" />
                    <path
                        d="M8.11999 15.1733C7.13332 15.1733 6.13999 14.7999 5.36666 14.0599C3.39999 12.1666 1.22666 9.14659 2.04666 5.55325C2.78666 2.29325 5.63332 0.833252 8.11999 0.833252C8.11999 0.833252 8.11999 0.833252 8.12666 0.833252C10.6133 0.833252 13.46 2.29325 14.2 5.55992C15.0133 9.15325 12.84 12.1666 10.8733 14.0599C10.1 14.7999 9.10666 15.1733 8.11999 15.1733ZM8.11999 1.83325C6.17999 1.83325 3.68666 2.86659 3.02666 5.77325C2.30666 8.91325 4.27999 11.6199 6.06666 13.3333C7.21999 14.4466 9.02666 14.4466 10.18 13.3333C11.96 11.6199 13.9333 8.91325 13.2267 5.77325C12.56 2.86659 10.06 1.83325 8.11999 1.83325Z"
                        fill="#737373" />
                </svg>
                {{ placeItem }}
            </p>
            <p class="t-c">
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12.6627 15.1675H5.00935C3.34935 15.1675 2.00269 13.8208 2.00269 12.1608V7.67415C2.00269 6.01415 3.34935 4.66748 5.00935 4.66748H12.6627C14.3227 4.66748 15.6694 6.01415 15.6694 7.67415V8.63416C15.6694 8.90749 15.4427 9.13416 15.1694 9.13416H13.8227C13.5894 9.13416 13.376 9.22082 13.2227 9.38082L13.216 9.38749C13.0294 9.56749 12.9427 9.81413 12.9627 10.0675C13.0027 10.5075 13.4227 10.8608 13.9027 10.8608H15.1694C15.4427 10.8608 15.6694 11.0875 15.6694 11.3608V12.1541C15.6694 13.8208 14.3227 15.1675 12.6627 15.1675ZM5.00935 5.66748C3.90269 5.66748 3.00269 6.56749 3.00269 7.67415V12.1608C3.00269 13.2675 3.90269 14.1675 5.00935 14.1675H12.6627C13.7694 14.1675 14.6694 13.2675 14.6694 12.1608V11.8675H13.9027C12.896 11.8675 12.0427 11.1208 11.9627 10.1608C11.9094 9.61414 12.1094 9.07416 12.5094 8.68083C12.856 8.32749 13.3227 8.13416 13.8227 8.13416H14.6694V7.67415C14.6694 6.56749 13.7694 5.66748 12.6627 5.66748H5.00935Z"
                        fill="#737373" />
                    <path
                        d="M2.50269 8.77399C2.22935 8.77399 2.00269 8.54732 2.00269 8.27399V5.22736C2.00269 4.23403 2.62935 3.33399 3.55602 2.98065L8.84935 0.980655C9.39602 0.773988 10.0027 0.847354 10.476 1.18069C10.956 1.51402 11.236 2.05402 11.236 2.63402V5.16734C11.236 5.44067 11.0094 5.66734 10.736 5.66734C10.4627 5.66734 10.236 5.44067 10.236 5.16734V2.63402C10.236 2.38068 10.116 2.14734 9.90269 2.00067C9.68935 1.85401 9.43602 1.82067 9.19602 1.914L3.90269 3.914C3.36269 4.12067 2.99602 4.64736 2.99602 5.22736V8.27399C3.00269 8.55399 2.77602 8.77399 2.50269 8.77399Z"
                        fill="#737373" />
                    <path
                        d="M13.9036 11.8678C12.8969 11.8678 12.0436 11.1212 11.9636 10.1612C11.9102 9.60784 12.1102 9.06785 12.5102 8.67452C12.8502 8.32785 13.3169 8.13452 13.8169 8.13452H15.2036C15.8636 8.15452 16.3702 8.67449 16.3702 9.31449V10.6879C16.3702 11.3279 15.8636 11.8478 15.2236 11.8678H13.9036ZM15.1902 9.13452H13.8236C13.5902 9.13452 13.3769 9.22118 13.2236 9.38118C13.0302 9.56785 12.9369 9.82117 12.9636 10.0745C13.0036 10.5145 13.4236 10.8678 13.9036 10.8678H15.2102C15.2969 10.8678 15.3769 10.7879 15.3769 10.6879V9.31449C15.3769 9.21449 15.2969 9.14119 15.1902 9.13452Z"
                        fill="#737373" />
                    <path
                        d="M10.1701 8.50098H5.50342C5.23008 8.50098 5.00342 8.27431 5.00342 8.00098C5.00342 7.72764 5.23008 7.50098 5.50342 7.50098H10.1701C10.4434 7.50098 10.6701 7.72764 10.6701 8.00098C10.6701 8.27431 10.4434 8.50098 10.1701 8.50098Z"
                        fill="#737373" />
                </svg>
                {{ price }} {{ $t('riyals') }}
            </p>
            <p class="t-c">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M8.00008 15.1667C4.50675 15.1667 1.66675 12.3267 1.66675 8.83333C1.66675 5.34 4.50675 2.5 8.00008 2.5C11.4934 2.5 14.3334 5.34 14.3334 8.83333C14.3334 12.3267 11.4934 15.1667 8.00008 15.1667ZM8.00008 3.5C5.06008 3.5 2.66675 5.89333 2.66675 8.83333C2.66675 11.7733 5.06008 14.1667 8.00008 14.1667C10.9401 14.1667 13.3334 11.7733 13.3334 8.83333C13.3334 5.89333 10.9401 3.5 8.00008 3.5Z"
                        fill="#737373" />
                    <path
                        d="M8.00024 9.16659C7.72691 9.16659 7.50024 8.93992 7.50024 8.66659V5.33325C7.50024 5.05992 7.72691 4.83325 8.00024 4.83325C8.27358 4.83325 8.50024 5.05992 8.50024 5.33325V8.66659C8.50024 8.93992 8.27358 9.16659 8.00024 9.16659Z"
                        fill="#737373" />
                    <path
                        d="M10.0002 1.83325H6.00024C5.72691 1.83325 5.50024 1.60659 5.50024 1.33325C5.50024 1.05992 5.72691 0.833252 6.00024 0.833252H10.0002C10.2736 0.833252 10.5002 1.05992 10.5002 1.33325C10.5002 1.60659 10.2736 1.83325 10.0002 1.83325Z"
                        fill="#737373" />
                </svg>
                مدة التنفيذ: {{ during }} {{ $t('day_s') }}
            </p>
            <p v-if="homeDelivery" class="t-c">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M8.66659 9.83342H1.33325C1.05992 9.83342 0.833252 9.60675 0.833252 9.33342V5.08008C0.833252 4.86674 0.966579 4.68006 1.15991 4.60673C1.35991 4.5334 1.57992 4.59342 1.71326 4.75342C2.11992 5.24008 2.75326 5.51341 3.37992 5.49341C3.91326 5.48007 4.40657 5.28009 4.77991 4.92676C4.95324 4.78009 5.09324 4.60675 5.19991 4.41341C5.40658 4.06008 5.50659 3.6734 5.49992 3.28007C5.48659 2.66674 5.21993 2.10674 4.7666 1.70007C4.6066 1.56007 4.55325 1.34006 4.62659 1.14673C4.69992 0.953395 4.8866 0.820068 5.09326 0.820068H9.99992C10.2733 0.820068 10.4999 1.04674 10.4999 1.32007V7.98674C10.4999 9.0134 9.67992 9.83342 8.66659 9.83342ZM1.83325 8.83342H8.66659C9.12659 8.83342 9.49992 8.46008 9.49992 8.00008V1.83341H6.12659C6.35992 2.26675 6.48659 2.75342 6.49992 3.26009C6.51325 3.84009 6.36658 4.41341 6.07324 4.91341C5.91324 5.20008 5.69325 5.47342 5.45325 5.67342C4.91991 6.18009 4.18658 6.48008 3.40658 6.50008C2.84658 6.52008 2.30659 6.38008 1.83993 6.12675V8.83342H1.83325Z"
                        fill="#737373" />
                    <path
                        d="M12.6666 13.8333H11.9999C11.7266 13.8333 11.4999 13.6066 11.4999 13.3333C11.4999 12.8733 11.1266 12.4999 10.6666 12.4999C10.2066 12.4999 9.83325 12.8733 9.83325 13.3333C9.83325 13.6066 9.60658 13.8333 9.33325 13.8333H6.66658C6.39325 13.8333 6.16658 13.6066 6.16658 13.3333C6.16658 12.8733 5.79325 12.4999 5.33325 12.4999C4.87325 12.4999 4.49992 12.8733 4.49992 13.3333C4.49992 13.6066 4.27325 13.8333 3.99992 13.8333H3.33325C1.95325 13.8333 0.833252 12.7133 0.833252 11.3333V9.33325C0.833252 9.05992 1.05992 8.83325 1.33325 8.83325H8.66658C9.12659 8.83325 9.49992 8.45992 9.49992 7.99992V3.33325C9.49992 3.05992 9.72658 2.83325 9.99992 2.83325H11.2266C11.8866 2.83325 12.4932 3.1866 12.8199 3.75993L13.9599 5.75326C14.0466 5.90659 14.0466 6.09992 13.9599 6.25326C13.8733 6.40659 13.7066 6.49992 13.5266 6.49992H12.6666C12.5733 6.49992 12.4999 6.57325 12.4999 6.66659V8.66659C12.4999 8.75992 12.5733 8.83325 12.6666 8.83325H14.6666C14.9399 8.83325 15.1666 9.05992 15.1666 9.33325V11.3333C15.1666 12.7133 14.0466 13.8333 12.6666 13.8333ZM12.4333 12.8333H12.6666C13.4933 12.8333 14.1666 12.1599 14.1666 11.3333V9.83325H12.6666C12.0266 9.83325 11.4999 9.30659 11.4999 8.66659V6.66659C11.4999 6.02659 12.0199 5.49992 12.6666 5.49992L11.9532 4.25326C11.8066 3.99326 11.5266 3.83325 11.2266 3.83325H10.4999V7.99992C10.4999 9.01325 9.67992 9.83325 8.66658 9.83325H1.83325V11.3333C1.83325 12.1599 2.50659 12.8333 3.33325 12.8333H3.56657C3.78657 12.0666 4.49325 11.4999 5.33325 11.4999C6.17325 11.4999 6.87993 12.0666 7.09993 12.8333H8.90658C9.12658 12.0666 9.83326 11.4999 10.6733 11.4999C11.5133 11.4999 12.2133 12.0666 12.4333 12.8333Z"
                        fill="#737373" />
                    <path
                        d="M5.33333 15.1667C4.32 15.1667 3.5 14.3467 3.5 13.3333C3.5 12.32 4.32 11.5 5.33333 11.5C6.34667 11.5 7.16667 12.32 7.16667 13.3333C7.16667 14.3467 6.34667 15.1667 5.33333 15.1667ZM5.33333 12.5C4.87333 12.5 4.5 12.8733 4.5 13.3333C4.5 13.7933 4.87333 14.1667 5.33333 14.1667C5.79333 14.1667 6.16667 13.7933 6.16667 13.3333C6.16667 12.8733 5.79333 12.5 5.33333 12.5Z"
                        fill="#737373" />
                    <path
                        d="M10.6666 15.1667C9.65325 15.1667 8.83325 14.3467 8.83325 13.3333C8.83325 12.32 9.65325 11.5 10.6666 11.5C11.6799 11.5 12.4999 12.32 12.4999 13.3333C12.4999 14.3467 11.6799 15.1667 10.6666 15.1667ZM10.6666 12.5C10.2066 12.5 9.83325 12.8733 9.83325 13.3333C9.83325 13.7933 10.2066 14.1667 10.6666 14.1667C11.1266 14.1667 11.4999 13.7933 11.4999 13.3333C11.4999 12.8733 11.1266 12.5 10.6666 12.5Z"
                        fill="#737373" />
                    <path
                        d="M14.6667 9.83333H12.6667C12.0267 9.83333 11.5 9.30667 11.5 8.66667V6.66667C11.5 6.02667 12.0267 5.5 12.6667 5.5H13.5267C13.7067 5.5 13.8733 5.59334 13.96 5.75334L15.1 7.75334C15.14 7.82667 15.1667 7.91333 15.1667 8V9.33333C15.1667 9.60667 14.94 9.83333 14.6667 9.83333ZM12.6667 6.5C12.5733 6.5 12.5 6.57333 12.5 6.66667V8.66667C12.5 8.76 12.5733 8.83333 12.6667 8.83333H14.1667V8.13334L13.2333 6.5H12.6667Z"
                        fill="#737373" />
                    <path
                        d="M3.32665 6.50008C2.40665 6.50008 1.53999 6.10007 0.953323 5.40007C0.85999 5.30007 0.760001 5.16007 0.673334 5.02673C0.360001 4.5534 0.186668 3.99341 0.173334 3.40674C0.146668 2.4334 0.560009 1.52007 1.30668 0.900065C1.87334 0.433398 2.55331 0.180081 3.27331 0.166748C4.05998 0.173415 4.84668 0.433416 5.43334 0.960083C6.10001 1.54675 6.48667 2.36676 6.50667 3.26009C6.52 3.84009 6.37332 4.41341 6.07999 4.91341C5.91999 5.20008 5.7 5.47342 5.46 5.67342C4.92666 6.18009 4.19332 6.48008 3.41332 6.50008C3.37999 6.50008 3.35332 6.50008 3.32665 6.50008ZM3.32665 1.16675C3.31332 1.16675 3.29999 1.16675 3.28666 1.16675C2.79999 1.17341 2.33331 1.35342 1.93998 1.67342C1.43331 2.09342 1.15333 2.72009 1.16666 3.38009C1.17999 3.78009 1.29333 4.1601 1.50667 4.47343C1.56667 4.56676 1.62665 4.6534 1.69999 4.7334C2.12665 5.24007 2.76 5.50674 3.38 5.49341C3.91333 5.48007 4.40665 5.28009 4.77998 4.92676C4.95332 4.78009 5.09332 4.60674 5.19999 4.41341C5.40665 4.06008 5.50666 3.6734 5.49999 3.28007C5.48666 2.66674 5.22001 2.10674 4.76668 1.70007C4.36668 1.36007 3.85999 1.16675 3.32665 1.16675Z"
                        fill="#737373" />
                    <path
                        d="M2.9665 4.49987C2.83984 4.49987 2.71982 4.45319 2.61982 4.35985L1.94648 3.71988C1.74648 3.52655 1.73984 3.21322 1.93318 3.01322C2.12651 2.81322 2.43984 2.80654 2.63984 2.99987L2.9665 3.31318L4.01318 2.29988C4.21318 2.10654 4.52651 2.11318 4.71984 2.31318C4.91317 2.51318 4.90649 2.82656 4.70649 3.01989L3.31314 4.36653C3.21314 4.45319 3.0865 4.49987 2.9665 4.49987Z"
                        fill="#737373" />
                </svg>
                التوصيل حتى المنزل
            </p>
            <p v-if="delivery" class="t-c">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10.16 15.1666H5.83996C4.57996 15.1666 3.65996 14.6399 3.30663 13.7333C2.93996 12.7866 3.27996 11.6133 4.15996 10.8199L7.2533 7.99992L4.15996 5.17992C3.27996 4.38658 2.93996 3.21325 3.30663 2.26659C3.65996 1.35325 4.57996 0.833252 5.83996 0.833252H10.16C11.42 0.833252 12.34 1.35992 12.6933 2.26659C13.06 3.21325 12.72 4.38658 11.84 5.17992L8.74663 7.99992L11.8466 10.8199C12.72 11.6133 13.0666 12.7866 12.7 13.7333C12.34 14.6399 11.42 15.1666 10.16 15.1666ZM7.99996 8.67325L4.8333 11.5533C4.2733 12.0666 4.02663 12.8133 4.23996 13.3666C4.43996 13.8799 5.00663 14.1666 5.83996 14.1666H10.16C10.9933 14.1666 11.56 13.8866 11.76 13.3666C11.9733 12.8133 11.7333 12.0666 11.1666 11.5533L7.99996 8.67325ZM5.83996 1.83325C5.00663 1.83325 4.43996 2.11325 4.23996 2.63325C4.02663 3.18659 4.26663 3.93325 4.8333 4.44659L7.99996 7.32658L11.1666 4.44659C11.7266 3.93325 11.9733 3.18659 11.76 2.63325C11.56 2.11992 10.9933 1.83325 10.16 1.83325H5.83996Z"
                        fill="#737373" />
                </svg>
                التسيلم عند توفر المنتج
            </p>

        </div>
        <div class="d-flex flex-wrap align-items-center justify-content-between">
            <p class="t-c w-75 m-0" v-sanitize.basic="description">
            </p>
            <div class="d-flex  gap-1 flex-wrap">
                <button v-if="false"
                    @click="router_push('service-provider-request-purchase-service-progress', { id: itemId })"
                    style="height: 40px; width: 100px;" class="btn-main px-2 ">
                    طلب تسليم
                </button>
                <button v-if="false" class="btn rounded-2 border-danger text-danger px-2 bg-transparent   ">
                    إلغاء الطلب
                </button>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'my-request-client-card',
    props: {
        itemId: {
            type: [String, Number],
        },
        name: {
            type: String,
        },
        service: {
            type: String,
        },
        state: {
            type: String,
        },
        status: {
            type: String,
            default: 'waiting'
        },
        homeDelivery: {
            type: Boolean,
            default: true
        },
        delivery: {
            type: Boolean,
            default: true
        },
        place: {
            type: String,
        },
        price: {
            type: [String, Number],
        },
        description: {
            type: String,
        },
        requests: {
            type: [String, Number],
        },
        during: {
            type: [String, Number],
        },
        dateRequest: {
            type: String,
        }

    },
    computed: {
        placeItem() {
            switch (this.state) {
                case 'online': return this.$t('online');
                case 'service': return this.$t('ready-service');
                case 'offline': return this.place;
                default:
                    return 'N/A';
            }
        },
        statusClass() {
            return `status-request-${this.status}`
        },
        statusName() {
            switch (this.status) {
                case 'finished': return "مكتمل";
                case 'waiting': return "قيد الانتظار";
                case 'underway': return "قيد التنفيذ";
                case 'cancel': return "ملغاة";


                default:
                    return this.status
            }
        }
    }
}
</script>
  
<style scoped>
.box {
    padding: 10px;
}

.box-title {
    cursor: pointer;
}
</style>    