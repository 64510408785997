<template>
  <svg :width="size" :height="size" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.652 30.334H8.34537C5.02536 30.334 2.33203 27.6406 2.33203 24.3206V15.3473C2.33203 12.0273 5.02536 9.33398 8.34537 9.33398H23.652C26.972 9.33398 29.6654 12.0273 29.6654 15.3473V17.2673C29.6654 17.814 29.212 18.2673 28.6654 18.2673H25.972C25.5054 18.2673 25.0787 18.4407 24.772 18.7607L24.7587 18.774C24.3854 19.134 24.212 19.6273 24.252 20.134C24.332 21.014 25.172 21.7206 26.132 21.7206H28.6654C29.212 21.7206 29.6654 22.174 29.6654 22.7206V24.3073C29.6654 27.6406 26.972 30.334 23.652 30.334ZM8.34537 11.334C6.13203 11.334 4.33203 13.134 4.33203 15.3473V24.3206C4.33203 26.534 6.13203 28.334 8.34537 28.334H23.652C25.8654 28.334 27.6654 26.534 27.6654 24.3206V23.734H26.132C24.1187 23.734 22.412 22.2406 22.252 20.3206C22.1454 19.2273 22.5454 18.1473 23.3454 17.3607C24.0387 16.654 24.972 16.2673 25.972 16.2673H27.6654V15.3473C27.6654 13.134 25.8654 11.334 23.652 11.334H8.34537Z"
      :fill="color" />
    <path
      d="M3.33203 17.546C2.78536 17.546 2.33203 17.0927 2.33203 16.546V10.4528C2.33203 8.4661 3.58536 6.66602 5.4387 5.95936L16.0254 1.95936C17.1187 1.54602 18.332 1.69275 19.2787 2.35942C20.2387 3.02609 20.7987 4.10608 20.7987 5.26608V10.3327C20.7987 10.8794 20.3454 11.3327 19.7987 11.3327C19.252 11.3327 18.7987 10.8794 18.7987 10.3327V5.26608C18.7987 4.75941 18.5587 4.29273 18.132 3.99939C17.7054 3.70606 17.1987 3.63939 16.7187 3.82606L6.13203 7.82605C5.05203 8.23939 4.3187 9.29276 4.3187 10.4528V16.546C4.33203 17.106 3.8787 17.546 3.33203 17.546Z"
      :fill="color" />
    <path
      d="M26.1313 23.7323C24.118 23.7323 22.4113 22.2389 22.2513 20.3189C22.1447 19.2123 22.5447 18.1323 23.3447 17.3456C24.0247 16.6523 24.958 16.2656 25.958 16.2656H28.7313C30.0513 16.3056 31.0647 17.3456 31.0647 18.6256V21.3723C31.0647 22.6523 30.0513 23.6923 28.7713 23.7323H26.1313ZM28.7047 18.2656H25.9713C25.5047 18.2656 25.078 18.439 24.7713 18.759C24.3847 19.1323 24.198 19.6389 24.2513 20.1456C24.3313 21.0256 25.1713 21.7323 26.1313 21.7323H28.7447C28.918 21.7323 29.078 21.5723 29.078 21.3723V18.6256C29.078 18.4256 28.918 18.279 28.7047 18.2656Z"
      :fill="color" />
    <path
      d="M18.6654 17H9.33203C8.78536 17 8.33203 16.5467 8.33203 16C8.33203 15.4533 8.78536 15 9.33203 15H18.6654C19.212 15 19.6654 15.4533 19.6654 16C19.6654 16.5467 19.212 17 18.6654 17Z"
      :fill="color" />
  </svg>
</template>

<script>
export default {
  name: 'empty-wallet',
  props: {
    size: {
      type: [String, Number],
      default: 32
    },
    color: {
      type: String,
      default: '#737373'
    }
  }
}
</script>

<style></style>
