<template>
 <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M28.0001 27H14.6667C14.1201 27 13.6667 26.5467 13.6667 26C13.6667 25.4533 14.1201 25 14.6667 25H28.0001C28.5467 25 29.0001 25.4533 29.0001 26C29.0001 26.5467 28.5467 27 28.0001 27Z" fill="#2CB7B3"/>
<path d="M28.0001 17.6665H14.6667C14.1201 17.6665 13.6667 17.2132 13.6667 16.6665C13.6667 16.1198 14.1201 15.6665 14.6667 15.6665H28.0001C28.5467 15.6665 29.0001 16.1198 29.0001 16.6665C29.0001 17.2132 28.5467 17.6665 28.0001 17.6665Z" fill="#2CB7B3"/>
<path d="M28.0001 8.3335H14.6667C14.1201 8.3335 13.6667 7.88016 13.6667 7.3335C13.6667 6.78683 14.1201 6.3335 14.6667 6.3335H28.0001C28.5467 6.3335 29.0001 6.78683 29.0001 7.3335C29.0001 7.88016 28.5467 8.3335 28.0001 8.3335Z" fill="#2CB7B3"/>
<path d="M5.33317 9.66659C5.07984 9.66659 4.82651 9.57326 4.62651 9.37326L3.29317 8.03992C2.90651 7.65326 2.90651 7.01325 3.29317 6.62659C3.67984 6.23992 4.31984 6.23992 4.70651 6.62659L5.33317 7.25325L8.62651 3.95992C9.01317 3.57326 9.65317 3.57326 10.0398 3.95992C10.4265 4.34659 10.4265 4.98659 10.0398 5.37326L6.03984 9.37326C5.83984 9.57326 5.58651 9.66659 5.33317 9.66659Z" fill="#2CB7B3"/>
<path d="M5.33317 19.0001C5.07984 19.0001 4.82651 18.9068 4.62651 18.7068L3.29317 17.3734C2.90651 16.9868 2.90651 16.3468 3.29317 15.9601C3.67984 15.5734 4.31984 15.5734 4.70651 15.9601L5.33317 16.5868L8.62651 13.2934C9.01317 12.9068 9.65317 12.9068 10.0398 13.2934C10.4265 13.6801 10.4265 14.3201 10.0398 14.7068L6.03984 18.7068C5.83984 18.9068 5.58651 19.0001 5.33317 19.0001Z" fill="#2CB7B3"/>
<path d="M5.33317 28.3331C5.07984 28.3331 4.82651 28.2398 4.62651 28.0398L3.29317 26.7064C2.90651 26.3198 2.90651 25.6798 3.29317 25.2931C3.67984 24.9064 4.31984 24.9064 4.70651 25.2931L5.33317 25.9198L8.62651 22.6264C9.01317 22.2398 9.65317 22.2398 10.0398 22.6264C10.4265 23.0131 10.4265 23.6531 10.0398 24.0398L6.03984 28.0398C5.83984 28.2398 5.58651 28.3331 5.33317 28.3331Z" fill="#2CB7B3"/>
</svg>

</template>

<script>
export default {

}
</script>

<style>

</style>