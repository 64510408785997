<template>
  <svg :width="size" :height="size" viewBox="0 0 32 32" fill="none"
xmlns="http://www.w3.org/2000/svg">
<path
    d="M28.0009 8.97332C27.9742 8.97332 27.9342 8.97332 27.8942 8.97332C20.8409 8.26665 13.8009 7.99999 6.82753 8.70666L4.10753 8.97332C3.54753 9.02665 3.0542 8.62666 3.00086 8.06666C2.94753 7.50666 3.34753 7.02666 3.8942 6.97332L6.6142 6.70666C13.7075 5.98666 20.8942 6.26666 28.0942 6.97332C28.6409 7.02666 29.0409 7.51999 28.9875 8.06666C28.9475 8.58666 28.5075 8.97332 28.0009 8.97332Z"
    :fill="color" />
<path
    d="M11.333 7.62666C11.2797 7.62666 11.2264 7.62666 11.1597 7.61332C10.6264 7.51999 10.253 6.99999 10.3464 6.46666L10.6397 4.71999C10.853 3.43999 11.1464 1.66666 14.253 1.66666H17.7464C20.8664 1.66666 21.1597 3.50666 21.3597 4.73332L21.653 6.46666C21.7464 7.01332 21.373 7.53332 20.8397 7.61332C20.293 7.70666 19.773 7.33332 19.693 6.79999L19.3997 5.06666C19.213 3.90666 19.173 3.67999 17.7597 3.67999H14.2664C12.853 3.67999 12.8264 3.86666 12.6264 5.05332L12.3197 6.78666C12.2397 7.27999 11.813 7.62666 11.333 7.62666Z"
    :fill="color" />
<path
    d="M20.2792 30.3333H11.7192C7.06588 30.3333 6.87921 27.76 6.73255 25.68L5.86588 12.2533C5.82588 11.7067 6.25255 11.2267 6.79921 11.1867C7.35921 11.16 7.82588 11.5733 7.86588 12.12L8.73255 25.5467C8.87921 27.5733 8.93255 28.3333 11.7192 28.3333H20.2792C23.0792 28.3333 23.1325 27.5733 23.2659 25.5467L24.1325 12.12C24.1725 11.5733 24.6525 11.16 25.1992 11.1867C25.7459 11.2267 26.1725 11.6933 26.1325 12.2533L25.2659 25.68C25.1192 27.76 24.9325 30.3333 20.2792 30.3333Z"
    :fill="color" />
<path
    d="M18.2134 23H13.7734C13.2268 23 12.7734 22.5467 12.7734 22C12.7734 21.4533 13.2268 21 13.7734 21H18.2134C18.7601 21 19.2134 21.4533 19.2134 22C19.2134 22.5467 18.7601 23 18.2134 23Z"
    :fill="color" />
<path
    d="M19.3327 17.6667H12.666C12.1193 17.6667 11.666 17.2133 11.666 16.6667C11.666 16.12 12.1193 15.6667 12.666 15.6667H19.3327C19.8793 15.6667 20.3327 16.12 20.3327 16.6667C20.3327 17.2133 19.8793 17.6667 19.3327 17.6667Z"
    :fill="color" />
</svg>
</template>

<script>
export default {
    props:{
        color:{
            type:String,
            default:'white'
        },
        size:{
            type:[String,Number],
            default:32
        }
    }
}
</script>

<style>

</style>