var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showInput)?_c('div',[_c('GmapAutocomplete',{on:{"place_changed":_vm.updateMapCenter}})],1):_vm._e(),_c('GmapMap',{staticStyle:{"width":"100%","height":"400px"},attrs:{"center":{
            lat: _vm.center?.lat ?? 24.774265,
            lng: _vm.center?.lng ?? 46.738586,
        },"zoom":10},on:{"click":_vm.updateMarkerPosition}},[_c('GmapMarker',{attrs:{"position":{
                lat: _vm.marker?.lat ?? 24.774265,
                lng: _vm.marker?.lng ?? 46.738586,
            },"draggable":_vm.isDraggable},on:{"dragend":_vm.updateMarkerPosition}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }