<template>
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M20.0003 30.3333H12.0003C4.76033 30.3333 1.66699 27.24 1.66699 20V12C1.66699 4.75996 4.76033 1.66663 12.0003 1.66663H20.0003C27.2403 1.66663 30.3337 4.75996 30.3337 12V20C30.3337 27.24 27.2403 30.3333 20.0003 30.3333ZM12.0003 3.66663C5.85366 3.66663 3.66699 5.85329 3.66699 12V20C3.66699 26.1466 5.85366 28.3333 12.0003 28.3333H20.0003C26.147 28.3333 28.3337 26.1466 28.3337 20V12C28.3337 5.85329 26.147 3.66663 20.0003 3.66663H12.0003Z"
                            fill="#1FB9B3" />
                        <path
                            d="M20.7734 25.6667C20.2268 25.6667 19.7734 25.2133 19.7734 24.6667V19.4667C19.7734 18.92 20.2268 18.4667 20.7734 18.4667C21.3201 18.4667 21.7734 18.92 21.7734 19.4667V24.6667C21.7734 25.2133 21.3201 25.6667 20.7734 25.6667Z"
                            fill="#1FB9B3" />
                        <path
                            d="M20.7734 10.9334C20.2268 10.9334 19.7734 10.48 19.7734 9.93337V7.33337C19.7734 6.78671 20.2268 6.33337 20.7734 6.33337C21.3201 6.33337 21.7734 6.78671 21.7734 7.33337V9.93337C21.7734 10.48 21.3201 10.9334 20.7734 10.9334Z"
                            fill="#1FB9B3" />
                        <path
                            d="M20.7733 17.8667C18.3066 17.8667 16.3066 15.8667 16.3066 13.4C16.3066 10.9334 18.3066 8.93335 20.7733 8.93335C23.24 8.93335 25.24 10.9334 25.24 13.4C25.24 15.8667 23.2266 17.8667 20.7733 17.8667ZM20.7733 10.9333C19.4133 10.9333 18.3066 12.04 18.3066 13.4C18.3066 14.76 19.4133 15.8667 20.7733 15.8667C22.1333 15.8667 23.24 14.76 23.24 13.4C23.24 12.04 22.12 10.9333 20.7733 10.9333Z"
                            fill="#1FB9B3" />
                        <path
                            d="M11.2266 25.6666C10.6799 25.6666 10.2266 25.2133 10.2266 24.6666V22.0666C10.2266 21.52 10.6799 21.0667 11.2266 21.0667C11.7732 21.0667 12.2266 21.52 12.2266 22.0666V24.6666C12.2266 25.2133 11.7866 25.6666 11.2266 25.6666Z"
                            fill="#1FB9B3" />
                        <path
                            d="M11.2266 13.5334C10.6799 13.5334 10.2266 13.08 10.2266 12.5334V7.33337C10.2266 6.78671 10.6799 6.33337 11.2266 6.33337C11.7732 6.33337 12.2266 6.78671 12.2266 7.33337V12.5334C12.2266 13.08 11.7866 13.5334 11.2266 13.5334Z"
                            fill="#1FB9B3" />
                        <path
                            d="M11.2264 23.0666C8.75977 23.0666 6.75977 21.0666 6.75977 18.6C6.75977 16.1333 8.75977 14.1333 11.2264 14.1333C13.6931 14.1333 15.6931 16.1333 15.6931 18.6C15.6931 21.0666 13.6931 23.0666 11.2264 23.0666ZM11.2264 16.1333C9.86643 16.1333 8.75977 17.24 8.75977 18.6C8.75977 19.96 9.86643 21.0666 11.2264 21.0666C12.5864 21.0666 13.6931 19.96 13.6931 18.6C13.6931 17.24 12.5998 16.1333 11.2264 16.1333Z"
                            fill="#1FB9B3" />
                    </svg>
</template>

<script>
export default {

}
</script>

<style>

</style>