<template>
    <div class="d-star-rate">
      <startItem
        v-for="id in starsList"
        :key="id"
        :itemId="id"
        :isSelected="value_ >= id"
        :nullable="nullable"
        :size="size"
        @input="updateValue(id)"
      />
    </div>
  </template>
  
  <script>
  import startItem from './star-item.vue';
  
  export default {
    name: 'd-star-rate',
    components: {
      startItem
    },
    props: {
      value: {
        type: Number,
        default: 0
      },
      nullable: {
        type: Boolean,
        default: false
      },
      size: {
        type: [String, Number],
        default: 32
      },
      stars: {
        type: [String, Number],
        default: 5
      }
    },
    computed: {
      starsList() {
        let list = [];
        for (let i = this.stars; i > 0; i--) {
          list.push(i);
        }
        return list;
      }
    },
    watch: {
      value(newValue) {
        this.value_ = newValue;
      }
    },
    data(vm) {
      return {
        value_: vm.value
      };
    },
    methods: {
      updateValue(id) {
        if (this.nullable && this.value_ === id) {
          this.value_ = 0;
        } else {
          this.value_ = id;
        }
        this.$emit('input', this.value_);
      }
    }
  };
  </script>
  
  <style>
  /* Add your styles here */
  </style>
  