<template>
 <svg
                    width="18"
                    height="9"
                    viewBox="0 0 18 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 8.79989C8.3 8.79989 7.6 8.52989 7.07 7.99989L0.55 1.47989C0.26 1.18989 0.26 0.709893 0.55 0.419893C0.84 0.129893 1.32 0.129893 1.61 0.419893L8.13 6.93989C8.61 7.41989 9.39 7.41989 9.87 6.93989L16.39 0.419893C16.68 0.129893 17.16 0.129893 17.45 0.419893C17.74 0.709893 17.74 1.18989 17.45 1.47989L10.93 7.99989C10.4 8.52989 9.7 8.79989 9 8.79989Z"
                      fill="#1FB9B3"
                    />
</svg>
</template>
<script>
export default {
  name:'ArrowDownIcon',
  props:{
    color:{
      type:String,
      default:'white'
    }
  }
}
</script>