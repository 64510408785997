<template>
  <svg :accent-height="size" :height="size" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.8957 28.5754L14.8939 28.5748C12.3672 27.7083 9.08833 25.7334 6.44233 22.8354C3.79746 19.9387 1.79169 16.1274 1.79169 11.5864C1.79169 6.98824 5.50957 3.25806 10.08 3.25806C12.3002 3.25806 14.3749 4.1249 15.9113 5.6744L16 5.76392L16.0888 5.6744C17.6252 4.1249 19.6998 3.25806 21.92 3.25806C26.4903 3.25806 30.2084 7.00145 30.2084 11.5864C30.2084 16.1341 28.2026 19.9454 25.5577 22.8404C22.9118 25.7367 19.6329 27.7083 17.1061 28.5748L17.1043 28.5754C16.7886 28.6891 16.4039 28.7414 16 28.7414C15.5961 28.7414 15.2114 28.6891 14.8957 28.5754ZM15.4662 26.9313L15.4672 26.9316C15.6062 26.978 15.812 26.9981 16.0067 26.9981C16.2014 26.9981 16.4072 26.978 16.5462 26.9316L16.5473 26.9313C18.0972 26.3989 21.0736 24.8627 23.6644 22.3138C26.2567 19.7634 28.4717 16.1899 28.4717 11.5864C28.4717 7.95803 25.5364 5.00806 21.9334 5.00806C19.867 5.00806 17.9497 5.9736 16.7129 7.64538L16.7127 7.64557C16.5537 7.86136 16.2878 7.97472 16.0134 7.97472C15.7389 7.97472 15.473 7.86136 15.314 7.64557L15.3134 7.64472C14.05 5.96026 12.1465 5.00806 10.08 5.00806C6.47698 5.00806 3.54169 7.95803 3.54169 11.5864C3.54169 16.1832 5.75333 19.7567 8.34396 22.3088C10.933 24.8593 13.9094 26.3989 15.4662 26.9313Z"
      :fill="color" :stroke="stroke" stroke-width="0.25" />
  </svg>
</template>
<script>
export default {
  name: 'HeartIcon',
  props: {
    size: {
      type: [String, Number],
      default: 32
    },
    color: {
      type: String,
      default: '#1FB9B3'
    },
    stroke: {
      type: String,
      default: '#737373'
    }
  }
}
</script>