<template>
  <svg :width="size" :height="size" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15.9999 21.7734C12.8132 21.7734 10.2266 19.1867 10.2266 16C10.2266 12.8134 12.8132 10.2267 15.9999 10.2267C19.1866 10.2267 21.7732 12.8134 21.7732 16C21.7732 19.1867 19.1866 21.7734 15.9999 21.7734ZM15.9999 12.2267C13.9199 12.2267 12.2266 13.92 12.2266 16C12.2266 18.08 13.9199 19.7734 15.9999 19.7734C18.0799 19.7734 19.7732 18.08 19.7732 16C19.7732 13.92 18.0799 12.2267 15.9999 12.2267Z"
                    :fill="color" />
                <path
                    d="M16.0004 28.0267C10.9871 28.0267 6.25376 25.0934 3.00043 20.0001C1.5871 17.8001 1.5871 14.2134 3.00043 12.0001C6.2671 6.90672 11.0004 3.97339 16.0004 3.97339C21.0004 3.97339 25.7338 6.90672 28.9871 12.0001C30.4004 14.2001 30.4004 17.7867 28.9871 20.0001C25.7338 25.0934 21.0004 28.0267 16.0004 28.0267ZM16.0004 5.97339C11.6938 5.97339 7.57376 8.56006 4.69376 13.0801C3.69376 14.6401 3.69376 17.3601 4.69376 18.9201C7.57376 23.4401 11.6938 26.0267 16.0004 26.0267C20.3071 26.0267 24.4271 23.4401 27.3071 18.9201C28.3071 17.3601 28.3071 14.6401 27.3071 13.0801C24.4271 8.56006 20.3071 5.97339 16.0004 5.97339Z"
                    :fill="color" />
            </svg>
</template>

<script>
export default {
    name:'eye-open-icon',
  props:{
    size:{
      type:[String,Number],
      default:32
    },
    color:{
      type:String,
      default:'#1FB9B3'
    }
  }
}
</script>

<style>

</style>