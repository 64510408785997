<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.25999 18.8898C7.17999 18.8898 7.1 18.8798 7.02 18.8698C6.42 18.7898 5.91 18.4098 5.66 17.8698L4.39999 15.0997C2.39999 13.6197 1.25 11.4098 1.25 9.00977C1.25 4.73977 4.95 1.25977 9.5 1.25977C12.71 1.25977 15.64 3.01976 16.97 5.74976C17.48 6.75976 17.75 7.85977 17.75 9.00977C17.75 13.1698 14.24 16.5798 9.86 16.7498L8.60001 18.2698C8.26001 18.6598 7.76999 18.8898 7.25999 18.8898ZM9.5 2.74976C5.78 2.74976 2.75 5.54976 2.75 8.99976C2.75 10.9698 3.72999 12.7897 5.42999 13.9897C5.53999 14.0697 5.62999 14.1697 5.67999 14.2897L7.02 17.2397C7.07 17.3497 7.17 17.3798 7.22 17.3798C7.27 17.3898 7.37 17.3797 7.44 17.2997L8.92 15.5198C9.06 15.3498 9.27 15.2498 9.5 15.2498C13.22 15.2498 16.25 12.4498 16.25 8.99976C16.25 8.08976 16.04 7.21973 15.63 6.40973C14.55 4.17973 12.14 2.74976 9.5 2.74976Z" :fill="color"/>
                                        <path d="M16.7401 22.7491C16.2201 22.7491 15.7401 22.5191 15.4001 22.1191L14.1401 20.6091C11.7201 20.5091 9.44008 19.3991 7.98008 17.5991C7.75008 17.3191 7.76009 16.9192 7.99009 16.6492L8.92008 15.5292C9.06008 15.3592 9.27008 15.2591 9.50008 15.2591C13.2201 15.2591 16.2501 12.4591 16.2501 9.00914C16.2501 8.09914 16.0401 7.22911 15.6301 6.41911C15.5001 6.15911 15.5301 5.84913 15.7101 5.61913C15.8901 5.38913 16.1901 5.28911 16.4701 5.34911C20.1701 6.19911 22.7501 9.28913 22.7501 12.8691C22.7501 15.2691 21.6001 17.4792 19.6001 18.9592L18.3401 21.7291C18.0801 22.2891 17.5701 22.6691 16.9601 22.7391C16.8901 22.7491 16.8101 22.7491 16.7401 22.7491ZM9.56008 17.0991C10.8101 18.3691 12.6101 19.1091 14.5001 19.1091C14.7201 19.1091 14.9301 19.2091 15.0801 19.3791L16.5601 21.1591C16.6301 21.2491 16.7201 21.2591 16.7801 21.2491C16.8201 21.2491 16.9301 21.2191 16.9901 21.0991L18.3301 18.1492C18.3901 18.0292 18.4701 17.9191 18.5801 17.8491C20.2801 16.6491 21.2601 14.8291 21.2601 12.8591C21.2601 10.4591 19.7901 8.32916 17.5501 7.27916C17.6901 7.83916 17.7601 8.40913 17.7601 8.99913C17.7601 13.1591 14.2501 16.5691 9.87008 16.7391L9.56008 17.0991Z" :fill="color"/>
                                        <path d="M12 9.75H7C6.59 9.75 6.25 9.41 6.25 9C6.25 8.59 6.59 8.25 7 8.25H12C12.41 8.25 12.75 8.59 12.75 9C12.75 9.41 12.41 9.75 12 9.75Z" :fill="color"/>
</svg>
</template>

<script>
export default {
props:{
    size:{
        type:Number,
        default:24
    },
    color:{
        type:String,
        default:"#979797"
    }
}
}
</script>

<style>

</style>