<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.0103 17.0003C11.1603 17.0003 10.3003 16.7803 9.63031 16.3503L3.61031 12.4203C2.49031 11.6903 1.82031 10.4603 1.82031 9.12028C1.82031 7.78028 2.49031 6.55028 3.61031 5.82028L9.64031 1.90028C10.9803 1.03028 13.0703 1.03028 14.4003 1.91028L20.3903 5.84028C21.5003 6.57028 22.1703 7.80028 22.1703 9.13028C22.1703 10.4603 21.5003 11.6903 20.3903 12.4203L14.4003 16.3503C13.7303 16.7903 12.8703 17.0003 12.0103 17.0003ZM12.0103 2.75028C11.4403 2.75028 10.8703 2.88028 10.4603 3.16028L4.44031 7.08028C3.74031 7.54028 3.33031 8.28028 3.33031 9.12028C3.33031 9.96028 3.73031 10.7003 4.44031 11.1603L10.4603 15.0903C11.2903 15.6303 12.7503 15.6303 13.5803 15.0903L19.5703 11.1603C20.2703 10.7003 20.6703 9.96028 20.6703 9.12028C20.6703 8.28028 20.2703 7.54028 19.5703 7.08028L13.5803 3.15028C13.1603 2.89028 12.5903 2.75028 12.0103 2.75028Z" :fill="color"/>
<path d="M11.9992 22.7501C11.5592 22.7501 11.1092 22.6901 10.7492 22.5701L7.5592 21.5101C6.0492 21.0101 4.8592 19.3601 4.8692 17.7701L4.8792 13.0801C4.8792 12.6701 5.2192 12.3301 5.6292 12.3301C6.0392 12.3301 6.3792 12.6701 6.3792 13.0801L6.3692 17.7701C6.3692 18.7101 7.1492 19.7901 8.0392 20.0901L11.2292 21.1501C11.6292 21.2801 12.3692 21.2801 12.7692 21.1501L15.9592 20.0901C16.8492 19.7901 17.6292 18.7101 17.6292 17.7801V13.1401C17.6292 12.7301 17.9692 12.3901 18.3792 12.3901C18.7892 12.3901 19.1292 12.7301 19.1292 13.1401V17.7801C19.1292 19.3701 17.9492 21.0101 16.4392 21.5201L13.2492 22.5801C12.8892 22.6901 12.4392 22.7501 11.9992 22.7501Z" :fill="color"/>
<path d="M21.4004 15.75C20.9904 15.75 20.6504 15.41 20.6504 15V9C20.6504 8.59 20.9904 8.25 21.4004 8.25C21.8104 8.25 22.1504 8.59 22.1504 9V15C22.1504 15.41 21.8104 15.75 21.4004 15.75Z" :fill="color"/>
</svg>
</template>

<script>
export default {
    name:'academy-icon',
    props:{
    size:{
      type:[String,Number],
      default:24
    },
    color:{
      type:String,
      default:'#CDD7D8'
    }
  }
}
</script>

<style>

</style>