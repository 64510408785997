<template>
  <svg :width="size" :height="size" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M25.893 15.6666H21.333C20.7863 15.6666 20.333 15.2133 20.333 14.6666V5.34662C20.333 4.35996 20.7197 3.43996 21.413 2.74663C22.1063 2.05329 23.0263 1.66663 24.013 1.66663H24.0263C25.693 1.67996 27.2663 2.33329 28.4663 3.51996C29.6663 4.73329 30.3197 6.33329 30.3197 7.99996V11.2266C30.333 13.88 28.5463 15.6666 25.893 15.6666ZM22.333 13.6666H25.893C27.4397 13.6666 28.333 12.7733 28.333 11.2266V7.99996C28.333 6.85329 27.8797 5.75996 27.0663 4.93329C26.253 4.13329 25.1597 3.67996 24.0263 3.66663C24.0263 3.66663 24.0263 3.66663 24.013 3.66663C23.573 3.66663 23.1463 3.83996 22.8263 4.15996C22.5063 4.47996 22.333 4.89329 22.333 5.34662V13.6666Z"
                            :fill="color" />
                        <path
                            d="M12.0003 31.1066C11.3737 31.1066 10.787 30.8666 10.347 30.4133L8.13366 28.1866C8.01366 28.0666 7.82699 28.0533 7.69366 28.16L5.40033 29.8666C4.69366 30.4 3.76033 30.4933 2.96033 30.0933C2.16033 29.6933 1.66699 28.8933 1.66699 28V7.99996C1.66699 3.97329 3.97366 1.66663 8.00033 1.66663H24.0003C24.547 1.66663 25.0003 2.11996 25.0003 2.66663C25.0003 3.21329 24.547 3.66663 24.0003 3.66663C23.0803 3.66663 22.3337 4.41329 22.3337 5.33329V28C22.3337 28.8933 21.8403 29.6933 21.0403 30.0933C20.2403 30.4933 19.307 30.4133 18.6003 29.88L16.3203 28.1733C16.187 28.0666 16.0003 28.0933 15.8937 28.2L13.6537 30.44C13.2137 30.8666 12.627 31.1066 12.0003 31.1066ZM7.88033 26.0933C8.49366 26.0933 9.09366 26.32 9.54699 26.7866L11.7603 29.0133C11.8403 29.0933 11.947 29.1066 12.0003 29.1066C12.0537 29.1066 12.1603 29.0933 12.2403 29.0133L14.4803 26.7733C15.307 25.9466 16.6137 25.8666 17.5337 26.5733L19.8003 28.2666C19.947 28.3733 20.0803 28.3333 20.147 28.2933C20.2137 28.2533 20.3337 28.1733 20.3337 28V5.33329C20.3337 4.73329 20.4803 4.15996 20.7337 3.66663H8.00033C5.04033 3.66663 3.66699 5.03996 3.66699 7.99996V28C3.66699 28.1866 3.78699 28.2666 3.85366 28.3066C3.93366 28.3466 4.06699 28.3733 4.20033 28.2666L6.48033 26.56C6.89366 26.2533 7.38699 26.0933 7.88033 26.0933Z"
                            :fill="color" />
                        <path
                            d="M16 18.3467H12C11.4533 18.3467 11 17.8933 11 17.3467C11 16.8 11.4533 16.3467 12 16.3467H16C16.5467 16.3467 17 16.8 17 17.3467C17 17.8933 16.5467 18.3467 16 18.3467Z"
                            :fill="color" />
                        <path
                            d="M16 13.0133H12C11.4533 13.0133 11 12.56 11 12.0133C11 11.4666 11.4533 11.0133 12 11.0133H16C16.5467 11.0133 17 11.4666 17 12.0133C17 12.56 16.5467 13.0133 16 13.0133Z"
                            :fill="color" />
                        <path
                            d="M7.96029 13.3467C7.22695 13.3467 6.62695 12.7467 6.62695 12.0134C6.62695 11.2801 7.22695 10.6801 7.96029 10.6801C8.69362 10.6801 9.29362 11.2801 9.29362 12.0134C9.29362 12.7467 8.69362 13.3467 7.96029 13.3467Z"
                            :fill="color" />
                        <path
                            d="M7.96029 18.68C7.22695 18.68 6.62695 18.08 6.62695 17.3466C6.62695 16.6133 7.22695 16.0133 7.96029 16.0133C8.69362 16.0133 9.29362 16.6133 9.29362 17.3466C9.29362 18.08 8.69362 18.68 7.96029 18.68Z"
                            :fill="color" />
                    </svg>
</template>

<script>
export default {
    name:'invoice-icon',
  props:{
    size:{
      type:[String,Number],
      default:32
    },
    color:{
      type:String,
      default:'#1FB9B3'
    }
  }
}
</script>

<style>

</style>