<template>
    <div class="user-story-card">
        <div class="user-story-card__image border-bottom">
            <img class="w-100 rounded-top" :src="image" :alt="name">
        </div>
        <div class="card-content p-2">
            <div class="d-flex justify-content-between mt-4">
                <div class="category-name">ريادة الأعمال</div>
                <div class="reading-duration"> 2 دقيقة</div>
            </div>
            <h5 class="user-story-card_title text-two-lines">{{ title }}</h5>
            <div class="user-story-card__text" v-sanitize.basic="truncatedDescription"></div>

            <div v-if="userInfo" class="user-info d-flex align-items-center gap-2">
                <hr style="margin-bottom: 10px; background-color: #ECF0F6; border-color: #ECF0F6;" />
                <img :src="userInfo.image" class="user-img rounded-circle" width="40px" height="40px" alt="user-image" />
                <div class="user-info__content d-flex flex-column">
                    <span class="user-name">{{ userInfo.name }}</span>
                    <span class="user-job">{{ userInfo.job }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'story-card',
    props: {
        image: {
            type: String
        },
        name: {
            type: String
        },
        title: {
            type: String
        },
        description: {
            type: String
        },
        userInfo: {
            type: Object
        }
    },
    computed: {
        truncatedDescription() {
            const maxLength = 133;
            const plainText = this.stripHtmlTags(this.description);

            return plainText.length > maxLength ? plainText.substring(0, maxLength) + "..." : plainText;
        }
    },
    methods: {
        stripHtmlTags(html) {
            return html.replace(/<\/?[^>]+(>|$)/g, "");
        },
    }
}
</script>

<style lang="scss" scoped>

.user-story-card {
    max-width: 380px;
    width: 100%;
    min-height: 502px;
    border-radius: 10px;
    background-color: 10px;
    border: 0.7px solid #DEE3EB;

    .user-story-card__image {
        height: 240px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .category-name {
        font-size: 16px;
        font-weight: 600;
        color: #1FB9B3;
    }

    .reading-duration {
        width: 91px;
        height: 24px;
        border-radius: 4px;
        background-color: #EBEEF2;
        font-size: 14px;
        font-weight: 400;
        color: #495367;
        text-align: center;
    }

    .user-story-card_title {
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        color: #1A202E;
    }

    .user-story-card__text {
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        color: #979797;
    }

    .user-info {
        margin-top: 57px;

        .user-name, .user-job {
            font-size: 12px;
            font-weight: 400;
            line-height: 17px;
        }

        .user-name {
            color: #1FB9B3;
        }

        .user-job {
            color: #96A2BE;
        }
    }
}

// .box-img>img {
//     min-height: 259px;
// }

// .user-story-card_title {
//     height: 48px;
//     font-style: normal;
//     font-weight: 400;
//     font-size: 20px;
//     line-height: 24px;
//     /* identical to box height, or 120% */
//     text-transform: capitalize;
//     color: #414042;
//     margin: 0;
//     height: 48px;
// }

// .user-story-card__text>* {
//     font-style: normal;
//     font-weight: 400;
//     font-size: 12px;
//     line-height: 17px;
//     /* identical to box height, or 142% */
//     color: #979797;
// }

.user-story-card__job {
    color: #979797;
}

.user-story-card__name {
    color: #1FB9B3;
}

// .user-story-card__image {
//     height: 192px;
// }

// .user-story-card__image>img {
//     height: 100%;
//     width: 100%;
//     object-fit: fill;

// }

</style>