<template>
    <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z" :fill="color"/>
<path d="M11.9995 15.2602C11.8095 15.2602 11.6195 15.1902 11.4695 15.0402L8.46945 12.0402C8.17945 11.7502 8.17945 11.2702 8.46945 10.9802C8.75945 10.6902 9.23945 10.6902 9.52945 10.9802L11.9995 13.4502L14.4695 10.9802C14.7595 10.6902 15.2395 10.6902 15.5295 10.9802C15.8195 11.2702 15.8195 11.7502 15.5295 12.0402L12.5295 15.0402C12.3795 15.1902 12.1895 15.2602 11.9995 15.2602Z" :fill="color"/>
<path d="M12 15.2598C11.59 15.2598 11.25 14.9198 11.25 14.5098V6.50977C11.25 6.09977 11.59 5.75977 12 5.75977C12.41 5.75977 12.75 6.09977 12.75 6.50977V14.5098C12.75 14.9298 12.41 15.2598 12 15.2598Z" :fill="color"/>
<path d="M12.0004 18.2302C9.89043 18.2302 7.77043 17.8902 5.76043 17.2202C5.37043 17.0902 5.16043 16.6602 5.29043 16.2702C5.42043 15.8802 5.84043 15.6602 6.24043 15.8002C9.96043 17.0402 14.0504 17.0402 17.7704 15.8002C18.1604 15.6702 18.5904 15.8802 18.7204 16.2702C18.8504 16.6602 18.6404 17.0902 18.2504 17.2202C16.2304 17.9002 14.1104 18.2302 12.0004 18.2302Z" :fill="color"/>
</svg>
  </template>
  
  <script>
  export default {
      name:'download-icon',
      props:{
      size:{
        type:[String,Number],
        default:24
      },
      color:{
        type:String,
        default:'#737373'
      }
    }
  }
  </script>
  
  <style>
  
  </style>