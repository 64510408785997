<template>
  <div class="container mt-5">
    <sectionFilter />
  </div>
</template>
<script>
import sectionFilter from "./filter.vue";
export default {
  name: "recent-models",
  components: {
    sectionFilter,
  },
};
</script>

<style>
</style>