<template>
  <svg :width="size" :height="size" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.1953 25.8C15.6486 25.8 15.1953 25.3467 15.1953 24.8V22C15.1953 21.4533 15.6486 21 16.1953 21C16.742 21 17.1953 21.4533 17.1953 22V24.8C17.1953 25.3467 16.742 25.8 16.1953 25.8Z" :fill="color"/>
<path d="M23.8646 30.334H8.53125V28.0007C8.53125 25.974 10.1712 24.334 12.1979 24.334H20.1979C22.2246 24.334 23.8646 25.974 23.8646 28.0007V30.334ZM10.5312 28.334H21.8646V28.0007C21.8646 27.0807 21.1179 26.334 20.1979 26.334H12.1979C11.2779 26.334 10.5312 27.0807 10.5312 28.0007V28.334Z" :fill="color"/>
<path d="M24.1953 30.334H8.19531C7.64865 30.334 7.19531 29.8807 7.19531 29.334C7.19531 28.7873 7.64865 28.334 8.19531 28.334H24.1953C24.742 28.334 25.1953 28.7873 25.1953 29.334C25.1953 29.8807 24.742 30.334 24.1953 30.334Z" :fill="color"/>
<path d="M24.5691 16.588C24.2891 16.588 24.0091 16.468 23.8091 16.2414C23.5557 15.948 23.4891 15.5347 23.6491 15.188C24.1024 14.148 24.3291 13.0414 24.3291 11.8814V7.88138C24.3291 7.41471 24.2491 6.96138 24.0891 6.48138C24.0757 6.44138 24.0624 6.38805 24.0491 6.33471C24.0091 6.13471 23.9957 5.93471 23.9957 5.74805C23.9957 5.20138 24.4491 4.74805 24.9957 4.74805H25.7957C28.1824 4.74805 30.1291 6.74805 30.1291 9.21471C30.1291 11.2547 29.2891 13.268 27.8357 14.7214C27.8091 14.748 27.7291 14.8147 27.7157 14.828C26.9291 15.4814 26.0357 16.2147 24.8357 16.548C24.7424 16.5747 24.6624 16.588 24.5691 16.588ZM26.2357 6.78805C26.3024 7.14805 26.3291 7.52138 26.3291 7.88138V11.8814C26.3291 12.428 26.2891 12.948 26.2091 13.4814C26.2891 13.4147 26.3557 13.3614 26.4357 13.2947C27.5024 12.228 28.1291 10.7347 28.1291 9.21471C28.1291 8.01471 27.3157 7.00138 26.2357 6.78805Z" :fill="color"/>
<path d="M7.44052 16.5322C7.33385 16.5322 7.24052 16.5188 7.13385 16.4788C6.04052 16.1322 5.01385 15.4922 4.16052 14.6388C2.62719 12.9455 1.86719 11.0922 1.86719 9.13216C1.86719 6.70549 3.77385 4.79883 6.20052 4.79883H7.06719C7.40052 4.79883 7.72052 4.97216 7.90719 5.25216C8.09385 5.53216 8.12052 5.89216 7.98719 6.19883C7.77385 6.67883 7.66719 7.22549 7.66719 7.79883V11.7988C7.66719 12.9455 7.89385 14.0655 8.36052 15.1322C8.52052 15.4922 8.44052 15.9055 8.18719 16.1988C7.98719 16.4122 7.72052 16.5322 7.44052 16.5322ZM5.73385 6.83883C4.65385 7.05216 3.86719 7.98549 3.86719 9.13216C3.86719 10.5855 4.45385 11.9855 5.61385 13.2655C5.66719 13.3322 5.73385 13.3855 5.80052 13.4388C5.70719 12.8922 5.66719 12.3455 5.66719 11.7988V7.79883C5.66719 7.47883 5.69385 7.15883 5.73385 6.83883Z" :fill="color"/>
<path d="M15.9974 22.3327C10.3041 22.3327 5.66406 17.6927 5.66406 11.9993V7.99935C5.66406 4.50602 8.50406 1.66602 11.9974 1.66602H19.9974C23.4907 1.66602 26.3307 4.50602 26.3307 7.99935V11.9993C26.3307 17.6927 21.6907 22.3327 15.9974 22.3327ZM11.9974 3.66602C9.61073 3.66602 7.66406 5.61268 7.66406 7.99935V11.9993C7.66406 16.5993 11.3974 20.3327 15.9974 20.3327C20.5974 20.3327 24.3307 16.5993 24.3307 11.9993V7.99935C24.3307 5.61268 22.3841 3.66602 19.9974 3.66602H11.9974Z" :fill="color"/>
</svg>

</template>

<script>
export default {
    name:'cup-icon',
  props:{
    size:{
      type:[String,Number],
      default:32
    },
    color:{
      type:String,
      default:'#1FB9B3'
    }
  }
}
</script>

<style>

</style>