<template>
 <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 18V26H5V18H15ZM15 16H5C4.46957 16 3.96086 16.2107 3.58579 16.5858C3.21071 16.9609 3 17.4696 3 18V26C3 26.5304 3.21071 27.0391 3.58579 27.4142C3.96086 27.7893 4.46957 28 5 28H15C15.5304 28 16.0391 27.7893 16.4142 27.4142C16.7893 27.0391 17 26.5304 17 26V18C17 17.4696 16.7893 16.9609 16.4142 16.5858C16.0391 16.2107 15.5304 16 15 16ZM26 7V12H16V7H26ZM26 5H16C15.4696 5 14.9609 5.21071 14.5858 5.58579C14.2107 5.96086 14 6.46957 14 7V12C14 12.5304 14.2107 13.0391 14.5858 13.4142C14.9609 13.7893 15.4696 14 16 14H26C26.5304 14 27.0391 13.7893 27.4142 13.4142C27.7893 13.0391 28 12.5304 28 12V7C28 6.46957 27.7893 5.96086 27.4142 5.58579C27.0391 5.21071 26.5304 5 26 5ZM26 18V23H21V18H26ZM26 16H21C20.4696 16 19.9609 16.2107 19.5858 16.5858C19.2107 16.9609 19 17.4696 19 18V23C19 23.5304 19.2107 24.0391 19.5858 24.4142C19.9609 24.7893 20.4696 25 21 25H26C26.5304 25 27.0391 24.7893 27.4142 24.4142C27.7893 24.0391 28 23.5304 28 23V18C28 17.4696 27.7893 16.9609 27.4142 16.5858C27.0391 16.2107 26.5304 16 26 16ZM10 7V12H5V7H10ZM10 5H5C4.46957 5 3.96086 5.21071 3.58579 5.58579C3.21071 5.96086 3 6.46957 3 7V12C3 12.5304 3.21071 13.0391 3.58579 13.4142C3.96086 13.7893 4.46957 14 5 14H10C10.5304 14 11.0391 13.7893 11.4142 13.4142C11.7893 13.0391 12 12.5304 12 12V7C12 6.46957 11.7893 5.96086 11.4142 5.58579C11.0391 5.21071 10.5304 5 10 5Z" fill="#1FB9B3"/>
</svg>

</template>

<script>
export default {

}
</script>

<style>

</style>