<template>
    <div :class="['custom-slider', 'd-flex', className]">
        {{ min }} {{ unit }}    
        <input class="value-range" type="range" v-model="selectedValue" :min="min" :max="max" @input="updateValue">
        {{ max }} {{ unit }}    
        <input class="value-input" type="text" v-model="selectedValue" @input="updateValue" readonly>
    </div>
    </template>

    <script>

    export default {
        name: 'SliderControl',
        data() {
            return {
                selectedValue: this.value,
                min: 0,   max: 100,
            unit: '%'
        };
    },
    props: {
        value: {
            type: [String, Number],
            default: ''
        },
        className: {
            type: String,
            default: ''
        }
    },
    mounted() {
         console.log(`selected value: ${this.selectedValue}`)
        const rangeInput = this.$el.querySelector('.value-range');
        if (rangeInput) {
            const value = (this.selectedValue-this.min)/(this.max-this.min)*100
            rangeInput.style.setProperty("background", `linear-gradient(to left, #1FB9B3 0%, #1FB9B3 ${value}% , #D9D9D9 ${value}%, #D9D9D9 100%)`, "important")   ;
        }

    },
    methods: {
        updateValue(event) {
             
            this.$emit('input', this.selectedValue);
            const rangeInput = this.$el.querySelector('.value-range');
            if (rangeInput) {
                const value = (this.selectedValue-this.min)/(this.max-this.min)*100
                rangeInput.style.setProperty("background", `linear-gradient(to left, #1FB9B3 0%, #1FB9B3 ${value}% , #D9D9D9 ${value}%, #D9D9D9 100%)`, "important")   ;
                
            }
        }
    }
};
</script>

<style scoped>
    
    .custom-slider{
        --accent-color: #1FB9B3;
        --natural-color: #D9D9D9;
        gap: 10px;
        align-items: center;
        color: #737373;
    }
    input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none; 
        appearance: none;
        width: 20px; 
        height: 20px; 
        background: var(--accent-color);
        cursor: pointer;
        border-radius: 50%; 
    }
    .custom-slider input[type="range"].value-range {
        height: 10px !important;
        background: linear-gradient(to left, var(--accent-color) 0%, var(--accent-color) 50%, var(--natural-color) 50%, var(--natural-color) 100%) ;
        border-radius: 8px;
        height: 7px;
        width: 356px;
        outline: none;
        transition: background 450ms ease-in;
        -webkit-appearance: none;
    }
    input.value-input[type="text"]{
        border-radius: 8px;
        border: 1px solid #C8C9CC;
        background-color: #fff !important;
        color: #80859C;
        margin: 0 10px;
        height: 34px !important;
        width: 40px;
        text-align: center;
    }
</style>