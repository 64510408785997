export function getBackendErrors(errors) {
    const errorMessages = [];

    // Loop through the errors object
    for (const key in errors) {
        if (errors.hasOwnProperty(key)) {
            // Extract the array of messages for each key
            const messages = errors[key];
            messages.forEach(message => {
                errorMessages.push(message);
            });
        }
    }
    return errorMessages;
}