<template>
   <svg :width="size" :height="size" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M24 14.3333C23.4533 14.3333 23 13.88 23 13.3333V10.6666C23 6.46663 21.8133 3.66663 16 3.66663C10.1867 3.66663 9 6.46663 9 10.6666V13.3333C9 13.88 8.54667 14.3333 8 14.3333C7.45333 14.3333 7 13.88 7 13.3333V10.6666C7 6.79996 7.93333 1.66663 16 1.66663C24.0667 1.66663 25 6.79996 25 10.6666V13.3333C25 13.88 24.5467 14.3333 24 14.3333Z"
                    :fill="color" />
                <path
                    d="M22.667 30.3334H9.33366C3.45366 30.3334 1.66699 28.5467 1.66699 22.6667V20C1.66699 14.12 3.45366 12.3334 9.33366 12.3334H22.667C28.547 12.3334 30.3337 14.12 30.3337 20V22.6667C30.3337 28.5467 28.547 30.3334 22.667 30.3334ZM9.33366 14.3334C4.56033 14.3334 3.66699 15.24 3.66699 20V22.6667C3.66699 27.4267 4.56033 28.3334 9.33366 28.3334H22.667C27.4403 28.3334 28.3337 27.4267 28.3337 22.6667V20C28.3337 15.24 27.4403 14.3334 22.667 14.3334H9.33366Z"
                    :fill="color" />
                <path
                    d="M10.6663 22.6667C10.493 22.6667 10.3197 22.6267 10.1597 22.56C9.98633 22.4933 9.85302 22.4 9.71969 22.28C9.47969 22.0266 9.33301 21.6933 9.33301 21.3333C9.33301 21.16 9.37299 20.9867 9.43966 20.8267C9.50632 20.6533 9.59969 20.52 9.71969 20.3867C9.85302 20.2667 9.98633 20.1733 10.1597 20.1066C10.6397 19.8933 11.2397 20.0133 11.613 20.3867C11.733 20.52 11.8264 20.6667 11.893 20.8267C11.9597 20.9867 11.9997 21.16 11.9997 21.3333C11.9997 21.68 11.853 22.0266 11.613 22.28C11.3597 22.52 11.013 22.6667 10.6663 22.6667Z"
                    :fill="color" />
                <path
                    d="M16.0003 22.6668C15.6537 22.6668 15.307 22.5201 15.0537 22.2801C14.8137 22.0267 14.667 21.6934 14.667 21.3334C14.667 21.1601 14.6936 20.9867 14.7736 20.8267C14.8403 20.6667 14.9337 20.5201 15.0537 20.3868C15.3603 20.0801 15.827 19.9334 16.2537 20.0267C16.347 20.0401 16.427 20.0667 16.507 20.1067C16.587 20.1334 16.667 20.1734 16.747 20.2268C16.8137 20.2668 16.8803 20.3334 16.947 20.3868C17.067 20.5201 17.1603 20.6667 17.227 20.8267C17.2937 20.9867 17.3337 21.1601 17.3337 21.3334C17.3337 21.6934 17.187 22.0267 16.947 22.2801C16.8803 22.3334 16.8137 22.3867 16.747 22.4401C16.667 22.4934 16.587 22.5334 16.507 22.5601C16.427 22.6001 16.347 22.6268 16.2537 22.6401C16.1737 22.6534 16.0803 22.6668 16.0003 22.6668Z"
                    :fill="color" />
                <path
                    d="M21.3333 22.6668C20.9733 22.6668 20.64 22.5201 20.3867 22.2801C20.2667 22.1467 20.1733 22.0001 20.1066 21.8401C20.04 21.6801 20 21.5068 20 21.3334C20 20.9868 20.1467 20.6401 20.3867 20.3868C20.4533 20.3334 20.52 20.2801 20.5867 20.2268C20.6667 20.1734 20.7467 20.1334 20.8267 20.1067C20.9067 20.0667 20.9867 20.0401 21.0667 20.0267C21.5067 19.9334 21.96 20.0801 22.28 20.3868C22.52 20.6401 22.6667 20.9734 22.6667 21.3334C22.6667 21.5068 22.6267 21.6801 22.56 21.8401C22.4934 22.0134 22.4 22.1467 22.28 22.2801C22.0267 22.5201 21.68 22.6668 21.3333 22.6668Z"
                    :fill="color" />
            </svg>
</template>

<script>
export default {
    name:'lock-pass-icon',
  props:{
    size:{
      type:[String,Number],
      default:32
    },
    color:{
      type:String,
      default:'#1FB9B3'
    }
  }
}
</script>

<style>

</style>