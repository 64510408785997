<template>
    <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 21.25H7C3.35 21.25 1.25 19.15 1.25 15.5V8.5C1.25 4.85 3.35 2.75 7 2.75H17C20.65 2.75 22.75 4.85 22.75 8.5V15.5C22.75 19.15 20.65 21.25 17 21.25ZM7 4.25C4.14 4.25 2.75 5.64 2.75 8.5V15.5C2.75 18.36 4.14 19.75 7 19.75H17C19.86 19.75 21.25 18.36 21.25 15.5V8.5C21.25 5.64 19.86 4.25 17 4.25H7Z" :fill="color"/>
<path d="M12 15.75C9.93 15.75 8.25 14.07 8.25 12C8.25 9.93 9.93 8.25 12 8.25C14.07 8.25 15.75 9.93 15.75 12C15.75 14.07 14.07 15.75 12 15.75ZM12 9.75C10.76 9.75 9.75 10.76 9.75 12C9.75 13.24 10.76 14.25 12 14.25C13.24 14.25 14.25 13.24 14.25 12C14.25 10.76 13.24 9.75 12 9.75Z" :fill="color"/>
<path d="M19 7.75H16C15.59 7.75 15.25 7.41 15.25 7C15.25 6.59 15.59 6.25 16 6.25H19C19.41 6.25 19.75 6.59 19.75 7C19.75 7.41 19.41 7.75 19 7.75Z" :fill="color"/>
<path d="M8 17.75H5C4.59 17.75 4.25 17.41 4.25 17C4.25 16.59 4.59 16.25 5 16.25H8C8.41 16.25 8.75 16.59 8.75 17C8.75 17.41 8.41 17.75 8 17.75Z" :fill="color"/>
</svg>
   </template>
   
   <script>
   export default {
       name:'d-money-icon',
     props:{
       size:{
         type:[String,Number],
         default:24
       },
       color:{
         type:String,
         default:'currentColor'
       }
     }
   }
   </script>
