<template>
    <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.0495 19.4198C12.5095 19.4198 11.9395 19.3698 11.3195 19.2698L6.6295 18.5298C5.1495 18.2998 4.0095 17.8898 3.1595 17.2898C0.969499 15.7698 1.0995 13.1598 1.3795 11.3298L2.1195 6.63981C2.7995 2.33981 5.0195 0.729807 9.3195 1.39981L14.0095 2.13981C16.2195 2.48981 19.1895 3.37981 19.3795 7.10981C19.4195 7.74981 19.3795 8.47981 19.2395 9.33981L18.5095 14.0298C17.9295 17.6998 16.2195 19.4198 13.0495 19.4198ZM7.5295 2.74981C5.1495 2.74981 4.0695 3.95981 3.5995 6.87981L2.8595 11.5698C2.3995 14.5198 3.2395 15.5198 4.0195 16.0698C4.6795 16.5398 5.6095 16.8598 6.8595 17.0498L11.5495 17.7898C15.0395 18.3398 16.4795 17.2898 17.0295 13.7898L17.7595 9.09981C17.8795 8.34981 17.9195 7.71981 17.8795 7.18981V7.17981C17.7695 5.07981 16.5795 4.04981 13.7695 3.60981L9.0895 2.87981C8.5095 2.78981 7.9995 2.74981 7.5295 2.74981Z" :fill="color"/>
<path d="M14.6793 22.75C13.8293 22.75 12.8693 22.57 11.7593 22.2L7.24928 20.7C4.67928 19.8499 3.28928 18.6299 2.86928 16.8499C2.79928 16.5499 2.91928 16.2299 3.17928 16.0599C3.43928 15.8899 3.77928 15.8899 4.02928 16.0699C4.68928 16.5399 5.60928 16.8599 6.85928 17.0499L11.5493 17.79C15.0393 18.34 16.4793 17.2899 17.0293 13.7899L17.7593 9.09995C17.8793 8.34995 17.9193 7.71995 17.8793 7.18995C17.8693 6.92995 17.9993 6.66995 18.2293 6.51995C18.4593 6.36995 18.7493 6.35995 18.9893 6.48995C21.6693 7.91995 22.3793 10.2099 21.2193 13.7099L19.7193 18.2199C19.0093 20.3399 18.0793 21.64 16.7793 22.29C16.1493 22.6 15.4593 22.75 14.6793 22.75ZM5.72928 18.36C6.20928 18.6799 6.84928 18.99 7.71928 19.28L12.2293 20.78C13.9493 21.3499 15.1793 21.4 16.0993 20.95C17.0193 20.49 17.7193 19.4699 18.2893 17.7499L19.7893 13.2399C20.6093 10.7599 20.2893 9.43995 19.3393 8.54995C19.3193 8.79995 19.2793 9.05995 19.2393 9.33995L18.5093 14.0299C17.8293 18.33 15.6093 19.9399 11.3093 19.28L6.61928 18.54C6.30928 18.4799 6.00928 18.42 5.72928 18.36Z" :fill="color"/>
<path d="M8.24 9.72023C6.87 9.72023 5.75 8.60023 5.75 7.23023C5.75 5.86023 6.87 4.74023 8.24 4.74023C9.61 4.74023 10.73 5.86023 10.73 7.23023C10.73 8.60023 9.61 9.72023 8.24 9.72023ZM8.24 6.25023C7.7 6.25023 7.25 6.69023 7.25 7.24023C7.25 7.79023 7.69 8.23023 8.24 8.23023C8.78 8.23023 9.23 7.79023 9.23 7.24023C9.23 6.69023 8.78 6.25023 8.24 6.25023Z" :fill="color"/>

    </svg>
  
  </template>
  
  <script>
  export default {
      props:{
          color:{
              type:String,
              default:'#979797'
          },
          size:{
              type:[String,Number],
              default:24
          }
      }
  }
  </script>
  
  <style>
  
  </style>