export default () => ({
	token: null,
	user: null,
	partner: null,
	subscribeNetwork: null,
	subscribeProvider: null,
	subscribeAcademy: null,
	subscribeIncubator: null,
	subscribeIncubators: [],
	isSubscribeNetwork: false,
	isSubscribeProvider: false,
	isSubscribeAcademy: false,
	isSubscribeIncubator: false,
	isSubscribeIncubators: false,
	isProvider: false,
	academyRole: null,
	isInstructor: false,
	canUploadeCourse: false,
	isConsultant: false,
});