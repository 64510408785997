<template>
    <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.92084 10.75C5.21084 10.75 4.49084 10.49 3.94084 9.96C2.10084 8.21 1.36084 6.3 1.79084 4.43C2.30084 2.23 4.23084 1.25 5.92084 1.25C7.61084 1.25 9.55084 2.24 10.0508 4.43C10.4808 6.3 9.73084 8.21 7.90084 9.96C7.35084 10.49 6.64084 10.75 5.92084 10.75ZM3.25084 4.77C2.87084 6.44 3.98084 7.92 4.98084 8.87C5.51084 9.38 6.34084 9.37 6.87084 8.87C7.87084 7.92 8.98084 6.44 8.59084 4.76C8.25084 3.28 6.94084 2.75 5.93084 2.75C4.91084 2.75 3.60084 3.28 3.25084 4.77Z" :fill="color"/>
<path d="M5.96094 6.2002C5.41094 6.2002 4.96094 5.7502 4.96094 5.2002C4.96094 4.6502 5.40094 4.2002 5.96094 4.2002H5.97094C6.52094 4.2002 6.97094 4.6502 6.97094 5.2002C6.97094 5.7502 6.52094 6.2002 5.96094 6.2002Z" :fill="color"/>
<path d="M18.9406 22.7502C18.2306 22.7502 17.5106 22.4902 16.9506 21.9602C15.1006 20.2102 14.3506 18.3002 14.7906 16.4202C15.3006 14.2302 17.2406 13.2402 18.9406 13.2402C20.6406 13.2402 22.5806 14.2302 23.0906 16.4202C23.5206 18.2902 22.7706 20.2102 20.9206 21.9502C20.3706 22.4902 19.6606 22.7502 18.9406 22.7502ZM18.9406 14.7502C17.9206 14.7502 16.6006 15.2802 16.2506 16.7702C15.8606 18.4402 16.9806 19.9302 17.9806 20.8802C18.5106 21.3902 19.3506 21.3902 19.8906 20.8802C20.8906 19.9302 22.0106 18.4502 21.6206 16.7802C21.2906 15.2802 19.9706 14.7502 18.9406 14.7502Z" :fill="color"/>
<path d="M18.9805 18.2002C18.4305 18.2002 17.9805 17.7502 17.9805 17.2002C17.9805 16.6502 18.4205 16.2002 18.9805 16.2002H18.9905C19.5405 16.2002 19.9905 16.6502 19.9905 17.2002C19.9905 17.7502 19.5305 18.2002 18.9805 18.2002Z" :fill="color"/>
<path d="M12.4506 19.75H9.77062C8.61062 19.75 7.60062 19.05 7.20062 17.97C6.79062 16.89 7.09062 15.7 7.96062 14.93L15.9506 7.94C16.4306 7.52 16.4406 6.95 16.3006 6.56C16.1506 6.17 15.7706 5.75 15.1306 5.75H12.4506C12.0406 5.75 11.7006 5.41 11.7006 5C11.7006 4.59 12.0406 4.25 12.4506 4.25H15.1306C16.2906 4.25 17.3006 4.95 17.7006 6.03C18.1106 7.11 17.8106 8.3 16.9406 9.07L8.95062 16.06C8.47062 16.48 8.46062 17.05 8.60062 17.44C8.75062 17.83 9.13062 18.25 9.77062 18.25H12.4506C12.8606 18.25 13.2006 18.59 13.2006 19C13.2006 19.41 12.8606 19.75 12.4506 19.75Z" :fill="color"/>
<path d="M12.4506 19.75H9.77062C8.61062 19.75 7.60062 19.05 7.20062 17.97C6.79062 16.89 7.09062 15.7 7.96062 14.93L15.9506 7.94C16.4306 7.52 16.4406 6.95 16.3006 6.56C16.1506 6.17 15.7706 5.75 15.1306 5.75H12.4506C12.0406 5.75 11.7006 5.41 11.7006 5C11.7006 4.59 12.0406 4.25 12.4506 4.25H15.1306C16.2906 4.25 17.3006 4.95 17.7006 6.03C18.1106 7.11 17.8106 8.3 16.9406 9.07L8.95062 16.06C8.47062 16.48 8.46062 17.05 8.60062 17.44C8.75062 17.83 9.13062 18.25 9.77062 18.25H12.4506C12.8606 18.25 13.2006 18.59 13.2006 19C13.2006 19.41 12.8606 19.75 12.4506 19.75Z" :fill="color"/>
</svg>
  </template>
  
  <script>
  export default {
      name:'routing-icon',
      props:{
      size:{
        type:[String,Number],
        default:24
      },
      color:{
        type:String,
        default:'#979797'
      }
    }
  }
  </script>
  
  <style>
  
  </style>