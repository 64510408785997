<template>
    <svg :width="size" :height="size" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M19.9999 30.3333H11.9999C10.2399 30.3333 8.7732 30.16 7.5332 29.7867C7.07987 29.6533 6.78654 29.2133 6.8132 28.7466C7.14654 24.76 11.1865 21.6266 15.9999 21.6266C20.8132 21.6266 24.8399 24.7466 25.1865 28.7466C25.2265 29.2266 24.9332 29.6533 24.4665 29.7867C23.2265 30.16 21.7599 30.3333 19.9999 30.3333ZM8.95987 28.08C9.83987 28.2533 10.8399 28.3333 11.9999 28.3333H19.9999C21.1599 28.3333 22.1599 28.2533 23.0399 28.08C22.3332 25.52 19.4132 23.6266 15.9999 23.6266C12.5865 23.6266 9.66654 25.52 8.95987 28.08Z"
                              :fill="color" />
                          <path
                              d="M20.0003 2.66663H12.0003C5.33366 2.66663 2.66699 5.33329 2.66699 12V20C2.66699 25.04 4.18699 27.8 7.81366 28.8266C8.10699 25.36 11.667 22.6266 16.0003 22.6266C20.3337 22.6266 23.8937 25.36 24.187 28.8266C27.8137 27.8 29.3337 25.04 29.3337 20V12C29.3337 5.33329 26.667 2.66663 20.0003 2.66663ZM16.0003 18.8933C13.3603 18.8933 11.227 16.7466 11.227 14.1066C11.227 11.4666 13.3603 9.33329 16.0003 9.33329C18.6403 9.33329 20.7737 11.4666 20.7737 14.1066C20.7737 16.7466 18.6403 18.8933 16.0003 18.8933Z"
                              :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path
                              d="M15.9999 19.8934C12.8132 19.8934 10.2266 17.2934 10.2266 14.1067C10.2266 10.9201 12.8132 8.33337 15.9999 8.33337C19.1866 8.33337 21.7732 10.9201 21.7732 14.1067C21.7732 17.2934 19.1866 19.8934 15.9999 19.8934ZM15.9999 10.3334C13.9199 10.3334 12.2266 12.0267 12.2266 14.1067C12.2266 16.2001 13.9199 17.8934 15.9999 17.8934C18.0799 17.8934 19.7732 16.2001 19.7732 14.1067C19.7732 12.0267 18.0799 10.3334 15.9999 10.3334Z"
                              :fill="color" />
                      </svg>
  </template>
  
  <script>
  export default {
    props:{
    size:{
      type:[String,Number],
      default:32
    },
    color:{
      type:String,
      default:'#1FB9B3'
    }
  }
  }
  </script>
  
  <style>
  
  </style>