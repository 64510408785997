var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal fade",class:{ show: _vm.dialog },style:(_vm.styleObj),attrs:{"aria-hidden":!_vm.dialog ? 'true' : 'false',"aria-labelledby":`modal-large-${_vm.modalId}`,"tabindex":"-1"}},[_c('div',{staticClass:"modal-dialog",class:{
      'modal-xl': _vm.xl && !_vm.mlg,
      'modal-lg': _vm.mlg,
      'modal-dialog-centered': _vm.centered,
      'modal-fullscreen': _vm.fullscreen,
      'max-w-fit-content': _vm.fitContent,
      [_vm.dynamicTextClass]: true,
    },style:(_vm.styleProps)},[_c('div',{staticClass:"modal-content"},[(!_vm.hideHeader)?_c('div',{staticClass:"modal-header d-flex justify-content-between",staticStyle:{"margin-right":"auto","border-bottom":"0","width":"100%"}},[_c('h1',{staticClass:"modal-title",class:_vm.customHeaderClass,attrs:{"id":`modal-large-${_vm.modalId}`}},[_vm._t("header")],2),_c('button',{staticClass:"btn close-btn",staticStyle:{"margin":"0 !important","color":"#2cb7b3"},style:({ padding: _vm.fullscreen ? '0 30px' : null }),attrs:{"type":"button","aria-label":"Close"},on:{"click":function($event){return _vm.closeDialogLocal($event, true)}}},[_c('img',{attrs:{"src":require("@/assets/af-icons/close-icon.svg"),"alt":"close-icon"}})])]):_vm._e(),_c('div',{staticClass:"position-relative"},[(_vm.loading)?_c('d-overlays-simple'):_vm._e(),_c('div',{staticClass:"modal-body"},[_vm._t("default",null,{"close":_vm.closeDialogLocal,"dialog":_vm.dialog})],2),(!_vm.hideFooter && _vm.$slots.actions)?_c('div',{staticClass:"modal-footer justify-content-center"},[_vm._t("actions",null,{"close":_vm.closeDialogLocal,"dialog":_vm.dialog})],2):_vm._e(),(_vm.showFooterButton)?_c('div',{staticClass:"footer-btn__wrapper d-flex justify-content-center"},[_c('b-button',{staticClass:"action-btn btn btn-primary rounded-pill",on:{"click":function($event){return _vm.closeDialogLocal($event, true)}}},[_vm._v(_vm._s(_vm.footerButtonText))])],1):_vm._e()],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }