<template>
  <button class="main-button" @click="clickEvent" :style="buttonStyles">
    <slot name="prepend-icon"></slot>
    <slot></slot>
    <slot name="append-icon"></slot>
  </button>
</template>


<script>
export default {
  name: "MainButton",
  emits: ['trigger:onclick'],
  props: {
    width: {
      type: String,
      default: "100%"
    },
    minWidth: {
        type: String,
        default: "auto"
    },
    maxWidth: {
        type: String,
        default: "auto"
    },
    height: {
      type: String,
      default: "63px"
    },
    minHeight: {
        type: String,
        default: "auto"
    },
    maxHeight: {
        type: String,
        default: "auto"
    },
    borderRadius: {
      type: String,
      default: "12px"
    },
    bgColor: {
      type: String,
      default: "#1FB9B3"
    },
    borderColor: {
      type: String,
      default: "#1FB9B3"
    },
    fontSize: {
      type: String,
      default: "20px"
    },
    fontWeight: {
      type: String,
      default: "700"
    },

  },
  computed: {
    buttonStyles() {
      return {
        width: this.width,
        height: this.height,
        minWidth: this.minWidth,
        maxWidth: this.maxWidth,
        minHeight: this.minHeight,
        maxHeight: this.maxHeight,
        borderRadius: this.borderRadius,
        backgroundColor: this.bgColor,
        borderColor: this.borderColor,
        fontSize: this.fontSize,
        fontWeight: this.fontWeight,
      }
    }
  },
  methods: {
    clickEvent() {
      this.$emit('trigger:onclick')
    }
  }
}
</script>


<style lang="scss" scoped>
.main-button {
    width: 100%;
    height: 63px;
    border-radius: 12px;
    background-color: #1FB9B3;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    border-color: #1FB9B3;
}
</style>