export default {
    
    isMobile (state) {
    return state.isMobile
    },
    isTablet (state) {
      return state.isTablet
    },
    isDesktop (state) {
        return state.isTablet
    },
    isLoading (state) {
      return state.loading
  }   
  }