import LayoutDefault from "@/layouts/default/index.vue";

const routers = [
    {
        path: "media/magazine",
        meta: { layout: LayoutDefault, title: "Magazine" },
        name: "media-magazine",
        component: () =>
            import(
                /* webpackChunkName: "media-home" */ "../views/media/magazine/index.vue"
            ),
    },
    {
        path: "media/riadiat-tour",
        meta: { layout: LayoutDefault, title: "Riadiat Tour" },
        name: "media-tour",
        component: () =>
            import(
                /* webpackChunkName: "media-home" */ "../views/media/riadiat-tour/index.vue"
            ),
    },
    //   single tour details with tour id
    {
        path: "media/riadiat-tour/:id(\\d+)",
        meta: { layout: LayoutDefault, title: "Riadiat Tour Details" },
        name: "media-tour-details",
        component: () =>
            import(
                /* webpackChunkName: "media-home" */ "../views/media/riadiat-tour/tour-details.vue"
            ),
    },
    {
        path: "media/riadiat-tour/upcoming-events",
        meta: { layout: LayoutDefault, title: "Upcoming Events" },
        name: "media-upcoming-events",
        component: () =>
            import(
                /* webpackChunkName: "media-home" */ "../views/media/riadiat-tour/upcoming-events/index.vue"
            ),
    },
    {
        path: "media/podcast",
        meta: { layout: LayoutDefault, title: "Riadiat Podcast" },
        name: "media-podcast",
        component: () =>
            import(
                /* webpackChunkName: "media-home" */ "../views/media/podcast/index.vue"
            ),
    },
    {
        path: "media/:any(.*)*",
        meta: { layout: LayoutDefault },
        name: "NotFound-media",
        component: () =>
            import(
                /* webpackChunkName: "media-home" */ "../views/media/magazine/index.vue"
            ),
    },
];

export default routers;
